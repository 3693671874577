import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { StorageService } from "app/main/module/common/service/storage.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "environments/environment";

@Injectable()
export class AcademyCoursesService implements Resolve<any> {
    onCategoriesChanged: BehaviorSubject<any>;
    onCoursesChanged: BehaviorSubject<any>;
    baseUrl = environment.baseUrl;

    apiUrl = this.baseUrl + "/api/v1/";
    apiUrlPython = this.baseUrl + "/api/v1/";

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private userService: StorageService
    ) {
        // Set the defaults
        this.onCategoriesChanged = new BehaviorSubject({});
        this.onCoursesChanged = new BehaviorSubject({});
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            Promise.all([
                // this.getCategories(),
                // this.getCourses()
            ]).then(() => {
                resolve;
            }, reject);
        });
    }

    /**
     * Get categories
     *
     * @returns {Promise<any>}
     */

    deleteCourseById(resource_id) {
        // var labName = this.userService.getCookie("lab");
        const lab_id = this.userService.getCookie("lab_id");
        const token = this.userService.getCookie("token");
        const headers = new HttpHeaders().set("Authorization", `${token}`);
        return this._httpClient.delete(
            this.apiUrlPython +
                "rcenters?lab_id=" +
                lab_id +
                "&resource_id=" +
                resource_id,
            { headers }
        );
    }

    getcourseById(docId) {
        // var labName = this.userService.getCookie("lab");
        const lab_id = this.userService.getCookie("lab_id");
        return new Promise((resolve, reject) => {
            const token = this.userService.getCookie("token");
            // // // console.log(n);
            const headers = new HttpHeaders().set("Authorization", `${token}`);

            this._httpClient
                .get(
                    this.apiUrlPython +
                        "rcenters?lab_id=" +
                        lab_id +
                        "&category_id=" +
                        docId,
                    { headers }
                )
                .subscribe((response: any) => {
                    this.onCategoriesChanged.next(response);
                    resolve(response);
                }, reject);
        });
    }

    getcourseByIdfromResource(currentCategory, page) {
        // // console.log(e);
        // const labName = this.userService.getCookie("lab");
        const lab_id = this.userService.getCookie("lab_id");
        const token = this.userService.getCookie("token");
        const headers = new HttpHeaders().set("Authorization", `${token}`);
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: token,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": "true",
            }),
        };

        return this._httpClient.get(
            this.apiUrlPython +
                "rcenters?lab_id=" +
                lab_id +
                "&category_id=" +
                currentCategory +
                "&page=" +
                page +
                "&per_page=20",
            httpOptions
        );
    }

    getCategoriesfromLabs() {
        // const labName = this.userService.getCookie("lab");
        const lab_id = this.userService.getCookie("lab_id");
        const token = this.userService.getCookie("token");
        // // console.log(n);
        // const headers = new HttpHeaders().set('Authorization', `${token}`);
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: token,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": "true",
            }),
        };
        return this._httpClient.get(
            this.apiUrl + "lab?lab_id=" + lab_id,
            httpOptions
        );
    }

    getAllCategoriesResourceCenter(page) {
        // const labName = this.userService.getCookie("lab");
        const lab_id = this.userService.getCookie("lab_id");
        const token = this.userService.getCookie("token");
        // // // console.log(n);
        // const headers = new HttpHeaders().set('Authorization', `${token}`);
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: token,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": "true",
            }),
        };
        return this._httpClient.get(
            this.apiUrlPython +
                "rcenters?lab_id=" +
                lab_id +
                "&page=" +
                page +
                "&per_page=20",
            httpOptions
        );
    }

    addfile(data, formdata) {
        // const labName = this.userService.getCookie("lab");
        const lab_id = this.userService.getCookie("lab_id");
        const token = this.userService.getCookie("token");
        // // // console.log(n);
        const headers = new HttpHeaders().set("Authorization", `${token}`);
        return this._httpClient.post(
            this.apiUrlPython +
                "rcenters?lab_id=" +
                lab_id +
                "&category_id=" +
                data.category +
                "&display_name=" +
                data.fileName +
                "&title=" +
                data.title,
            formdata,
            { headers }
        );
    }

    getcourseBySearch(search, page) {
        // // console.log(e);
        // const labName = this.userService.getCookie("lab");
        const lab_id = this.userService.getCookie("lab_id");
        const token = this.userService.getCookie("token");
        const headers = new HttpHeaders().set("Authorization", `${token}`);
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: token,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": "true",
            }),
        };

        return this._httpClient.get(
            this.apiUrlPython +
                "rcenters?lab_id=" +
                lab_id +
                "&search=" +
                search +
                "&page=" +
                page +
                "&per_page=20",
            httpOptions
        );
    }

    getCourseBySearchByCategory(search, page, category_id) {
        const lab_id = this.userService.getCookie("lab_id");
        const token = this.userService.getCookie("token");
        const headers = new HttpHeaders().set("Authorization", `${token}`);
        // const httpOptions = {
        //     headers: new HttpHeaders({
        //         Authorization: token,
        //         "Content-Type": "application/json",
        //         "Access-Control-Allow-Origin": "*",
        //         "Access-Control-Allow-Credentials": "true",
        //     }),
        // };

        console.log(page);

        return this._httpClient.get(
            this.apiUrlPython +
                "rcenters?lab_id=" +
                lab_id +
                "&search=" +
                search +
                "&page=" +
                page +
                "&category_id=" +
                category_id +
                "&per_page=20",
            { headers }
        );
    }
}
