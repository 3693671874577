import {
    AfterContentInit,
    AfterViewChecked,
    AfterViewInit,
    Component,
    ElementRef,
    HostListener,
    OnInit,
    TemplateRef,
    ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { fuseAnimations } from "@fuse/animations";
import { AcademyCoursesService } from "../centers.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { Globals } from "../../../common/global";
import { StorageService } from "app/main/module/common/service/storage.service";
import { AuthenticationService } from "../../../common/service/authentication.service";
import { ApiService } from "../../../common/service/api.service";
import { fromEvent, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";

@Component({
    selector: "app-newrcenter",
    templateUrl: "./newrcenter.component.html",
    styleUrls: ["./newrcenter.component.scss"],
    animations: fuseAnimations,
})
export class NewrcenterComponent
    implements OnInit, AfterViewInit, AfterViewChecked
{
    categories: any[];
    dataByCatg: any[];
    courses: any[];
    coursesFilteredByCategory: any[];
    filteredCourses: any[];
    currentCategory: string;
    searchTerm: string;
    addCoursesForm: FormGroup;
    editCoursesForm: FormGroup;
    title: "";
    // length = 0;
    fileUrl = "";
    file: any;
    msg = "";
    date = new Date();
    selectedCategory: "";
    selectedCourseIdToDelete = "";
    selectedCourseObjToEdit: any;
    beforeEdit: any;
    hasFirstDataShown = true;
    titleCurrentValue = "";
    keyValuePair: any;
    holdDefaultCategeory = "";
    role = this.userService.getCookie("role");
    currentRoleData: any;
    showAction = false;
    memberInfo2: any;
    rcenter: any;
    display_name: any;
    page = 1;
    hasMoreData: boolean = true;
    readonly PAGE_SIZE = 20;
    isLoading: Boolean = false;
    @ViewChild("myInput") myInput: ElementRef;

    constructor(
        private _academyCoursesService: AcademyCoursesService,
        private _formBuilder: FormBuilder,
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private httpClient: HttpClient,
        private global: Globals,
        private authService: AuthenticationService,
        private userService: StorageService,
        private apiService: ApiService
    ) {
        // Set the defaults
        this.currentCategory = "all";
        this.searchTerm = "";
    }
    // hot Keys

    @HostListener("document:keydown", ["$event"])
    handleKeyboardEven(event: KeyboardEvent) {
        this.page = 1;
        const key = event.key;
        // // console.log(y);
        if (key === "Backspace" || key === "Delete") {
            this.searchTerm = "";
            this.filterCoursesByTerm(this.page);
        }
    }

    ngAfterViewInit() {
        this.scrollUp();
        fromEvent(this.myInput.nativeElement, "input")
            .pipe(
                map((event: Event) => (event.target as HTMLInputElement).value),
                debounceTime(1000),
                distinctUntilChanged()
            )
            .subscribe((searchTerm) => {
                if (searchTerm.length >= 1) {
                    this.isLoading = true;
                }
                this.onSearchTermChange(searchTerm);
            });
    }

    ngAfterViewChecked() {
        // Ensure the data is loaded and view is rendered
        if (this.hasFirstDataShown === false) {
            this.scrollUp();
        } // Adjust timeout as needed
    }

    @ViewChild("focus", { static: false }) focusElement!: ElementRef;
    scrollUp(): void {
        if (this.focusElement && this.focusElement.nativeElement) {
            this.focusElement.nativeElement.scrollIntoView({
                behavior: "auto",
                block: "start",
            });
        } else {
            console.log("focusElement not found or not yet available");
        }
    }

    ngOnInit(): void {
        // // console.log(");
        // // console.log(');
        this.courses = [];

        // Subscribe to categories
        this.holdDefaultCategeory = this.currentCategory;

        this.CategoriesfromLabs();
        this.AllCategoriesResourceCenter();

        this.addCoursesForm = this._formBuilder.group({
            fileUrl: [""],
            title: [
                "",
                [Validators.required, , Validators.pattern(/^[A-Za-z0-9 ]/)],
            ],
            // length: ['', [Validators.min(0), Validators.maxLength(3)],],
            categoryList: ["", [Validators.required]],
        });
        this.editCoursesForm = this._formBuilder.group({
            fileUrl: [""],
            title: [
                "",
                [Validators.required, Validators.pattern(/^[A-Za-z0-9 ]/)],
            ],
            // length: ['', [Validators.min(0), Validators.maxLength(3)],],
            categoryList: ["", [Validators.required]],
        });

        if (this.role === "support") {
            this.showAction = true;
        }
    }

    onSearchTermChange(term: string): void {
        this.page = 1;
        if (this.currentCategory === "all") {
            this.filterCoursesByTerm(this.page);
        } else {
            this.searchCourseByCategory(this.page);
        }
    }

    @HostListener("document:keydown", ["$event"])
    onKeyDown(event: KeyboardEvent): void {
        // const key = event.key;
        // if (key === "Backspace" || key === "Delete") {
        //     console.log(key);
        //     this.searchTerm = "";
        //     if (this.searchTerm === "" && this.currentCategory === "all") {
        //         console.log("all");
        //         this.AllCategoriesResourceCenter();
        //     }
        // }
    }

    AllCategoriesResourceCenter(): void {
        this.searchTerm = "";
        this.hasFirstDataShown = false;
        this.hasMoreData = false;
        this._academyCoursesService
            .getAllCategoriesResourceCenter(this.page)
            .subscribe((categories) => {
                // // console.log(s);
                Object.entries(categories).find(([key, value]) => {
                    if (key == "data") {
                        // if (key == 'status' && (value == 'success' || value == 'Success')) {

                        this.memberInfo2 = value;
                        this.courses = value;
                        this.hasFirstDataShown = true;
                        this.hasMoreData =
                            this.memberInfo2.length === this.PAGE_SIZE;
                        if (this.memberInfo2.length === 0) {
                            // @ts-ignore

                            this.openSnackBar(categories.message, "Ok");
                        }
                        // } else if (key == 'status' && value == 'failed') {
                        this.scrollUp();
                    } else {
                        // // console.log(e);
                    }
                });
                this.keyValuePair = this.makeKeyValuepair(this.courses);
            });
    }
    //getCategoriesfromLabs
    CategoriesfromLabs(): void {
        this._academyCoursesService
            .getCategoriesfromLabs()
            .subscribe((categories) => {
                // // // console.log(s);
                Object.entries(categories).find(([key, value]) => {
                    if (key == "data") {
                        this.memberInfo2 = value;
                        // // // console.log(2);
                        this.memberInfo2.forEach((element) => {
                            // // // console.log(r);
                            this.rcenter = element.rcenter;

                            this.rcenter.sort((a, b) =>
                                a.display_name.localeCompare(b.display_name)
                            );
                            if (this.rcenter != undefined) {
                                this.categories = element.rcenter;
                            }
                            this.rcenter.forEach((rc) => {
                                // // console.log(e);
                            });
                        });
                    } else {
                        // // console.log(e);
                    }
                });
                // this.categories = categories;
                this.keyValuePair = this.makeKeyValuepair(this.categories);
            });
    }

    //getcourseByIdfromResource
    courseByIdfromResource(): void {
        this.searchTerm = "";
        this.hasFirstDataShown = false;
        this.hasMoreData = false;
        this._academyCoursesService
            .getcourseByIdfromResource(this.currentCategory, this.page)
            .subscribe((categories) => {
                // // console.log(s);
                Object.entries(categories).find(([key, value]) => {
                    //@ts-ignore
                    if (categories && categories.data) {
                        // // // console.log(e);
                        this.memberInfo2 = value;
                        // // console.log(2);
                        this.memberInfo2.forEach((element) => {
                            // // console.log(d);
                            if (element.category_id === this.currentCategory) {
                            }
                        });
                        this.hasFirstDataShown = true;
                        this.hasMoreData =
                            this.memberInfo2.length === this.PAGE_SIZE;
                        this.scrollUp();
                        if (this.memberInfo2.length === 0) {
                            // @ts-ignore

                            this.openSnackBar(categories.message, "Ok");
                        }
                        return (this.courses = this.memberInfo2);
                    } else {
                        // // console.log(e);
                    }
                });
            });
    }
    /**
     * Filter centers by category
     */
    filterCoursesByCategory(): void {
        // Filter
        // // // console.log(y);
        this.page = 1;
        if (this.currentCategory === "all") {
            // // console.log(y);
            this.AllCategoriesResourceCenter();
            this.coursesFilteredByCategory = this.courses;
            this.filteredCourses = this.courses;
        } else {
            this.courseByIdfromResource();
            // // // console.log(s);
            this.coursesFilteredByCategory = this.courses.filter((course) => {
                // // // console.log(d);
                if (course.category_id === this.currentCategory) {
                    return course.category_id === this.currentCategory;
                }
            });
            // // // console.log(y);
            this.filteredCourses = [...this.coursesFilteredByCategory];
            // this.filteredCourses = [...this.coursesFilteredByCategory];
        }
        // Re-filter by search term

        // this.filterCoursesByTerm();
    }

    /**
     * Filter centers by term
     */
    filterCoursesByTerm(page): void {
        const searchTerm = this.searchTerm.toLowerCase();
        this.hasMoreData = false;
        this.memberInfo2 = [];

        if (searchTerm != "") {
            this.isLoading = true;
            this._academyCoursesService
                .getcourseBySearch(searchTerm, page)
                .subscribe((categories) => {
                    console.log(categories);
                    Object.entries(categories).find(([key, value]) => {
                        if (key == "data") {
                            this.isLoading = false;
                            this.memberInfo2 = value;
                            this.memberInfo2.forEach((element) => {
                                if (
                                    element.category_id === this.currentCategory
                                ) {
                                }
                            });
                            this.hasFirstDataShown = true;
                            this.hasMoreData =
                                this.memberInfo2.length === this.PAGE_SIZE;
                            this.scrollUp();

                            if (this.memberInfo2.length === 0) {
                                this.isLoading = false;
                                this.memberInfo2 = [];
                                this.courses = [];
                                console.log("Inside Data");

                                // @ts-ignore
                                this.openSnackBar(categories.message, "Ok");
                                return;
                            }

                            return (this.courses = this.memberInfo2);
                        } else {
                            this.isLoading = false;
                            this.memberInfo2 = [];
                            this.courses = [];
                            console.log("Outside Data");

                            return;
                        }
                    });
                });
        } else {
            // // // console.log(y);
            // if (this.coursesFilteredByCategory != undefined) {
            //     this.filteredCourses = this.coursesFilteredByCategory.filter(
            //         (course) => {
            //             return course.display_name
            //                 .toLowerCase()
            //                 .includes(searchTerm);
            //         }
            //     );
            // } else {
            //     this.filteredCourses = this.courses.filter((course) => {
            //         return course.display_name
            //             .toLowerCase()
            //             .includes(searchTerm);
            //     });
            // }
            // this.courses = this.filteredCourses;
            // // console.log(s);

            this.AllCategoriesResourceCenter();
            if ("object" != typeof this.courses) {
                window.location.reload();
            }
        }
    }

    searchCourseByCategory(page) {
        this.courses = [];
        this.memberInfo2 = [];
        const search = this.searchTerm.toLowerCase();
        this.hasMoreData = false;

        // console.log("categoryid: ", this.currentCategory);

        // console.log(search, search.length);

        if (search != "") {
            this.isLoading = true;
            this._academyCoursesService
                .getCourseBySearchByCategory(search, page, this.currentCategory)
                .subscribe((res) => {
                    Object.entries(res).find(([key, value]) => {
                        if (key == "data") {
                            this.isLoading = false;
                            const data = value;
                            if (data && data.length === 0) {
                                this.courses = [];
                            } else {
                                this.hasMoreData =
                                    data.length === this.PAGE_SIZE;
                                this.hasFirstDataShown = true;
                                this.courses = data;
                            }
                        }
                    });
                    if (this.courses.length === 0) {
                        // @ts-ignore

                        this.openSnackBar(res.message, "Ok");
                    }
                });
        } else {
            this.courseByIdfromResource();
            // console.log(search, search.length);
        }
    }

    handleSearch() {
        this.page = 1;
        if (this.currentCategory == "all") {
            this.filterCoursesByTerm(this.page);
        } else {
            this.searchCourseByCategory(this.page);
        }
    }

    openDialogWithRef(ref: TemplateRef<any>) {
        this.dialog.open(ref, { width: "520px", disableClose: true });
    }
    openDialogDeleteCourse(ref: TemplateRef<any>, courseId) {
        this.selectedCourseIdToDelete = courseId;
        this.dialog.open(ref, { width: "520px", disableClose: true });
    }
    openDialogEditCourse(ref: TemplateRef<any>, selectedCourseObj) {
        this.selectedCourseObjToEdit = selectedCourseObj;
        this._academyCoursesService
            .getcourseById(selectedCourseObj.category_id)
            .then((data: any) => {
                this.beforeEdit = data;
                this.editCoursesForm.setValue({
                    // @ts-ignore
                    categoryList: data.category,

                    title: data.title,

                    fileUrl: "",
                });
            })
            .catch((error) => {
                this.handleError(error);
            });

        this.dialog.open(ref, { width: "520px", disableClose: true });
    }

    openDetail(row) {
        // window.open(row.fileUrl, "_blank");
        window.open(row.resource_link, "_blank");
    }
    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 3000,
        });
    }

    onFileSelectEditForm(event) {
        if (event.target.files.length > 0) {
            const fileUrl = event.target.files[0];
            this.editCoursesForm.get("fileUrl").setValue(fileUrl);
        }
    }

    deleteCourse() {
        // // console.log(e);
        this.currentCategory = this.holdDefaultCategeory;
        this.hasFirstDataShown = false;
        this._academyCoursesService
            .deleteCourseById(this.selectedCourseIdToDelete)
            .subscribe((categories) => {
                // this._academyCoursesService.deleteCourseById(this.selectedCourseIdToDelete).then(result => {
                this.hasFirstDataShown = true;
                const msg = "File deleted successfully";
                this.openSnackBar(msg, "OK");
                this.onCancelFrm();
                this.ngOnInit()
            });
    }

    addCourses() {
        if (this.addCoursesForm.value.title.trim().length === 0) {
            alert("Please provide title!");
            return;
        }

        this.hasFirstDataShown = false;
        const dataObj: any = {
            title: this.addCoursesForm.value.title.trim(),
            category: this.addCoursesForm.value.categoryList,
            fileName: this.addCoursesForm.value.fileUrl.name,
            id: this.addCoursesForm.value.categoryList,
            updatedOn: new Date().getTime(),
            updated: this.getMonthlyTimestamp(),
            label: this.keyValuePair[this.addCoursesForm.value.categoryList],
        };        

        // getting file data into fileData
        const fileData = this.addCoursesForm.value.fileUrl;
        var formdata = new FormData();
        formdata.append("file", this.file);
        

        this._academyCoursesService
            .addfile(dataObj, formdata)
            .subscribe((categories) => {
                Object.entries(categories).find(([key, value]) => {
                    // if (key == 'data') {
                    
                    if (
                        key == "status" &&
                        (value == "success" || value == "Success")
                    ) {
                        alert(key + "= " + value);
                        this.memberInfo2 = value;
                        // // console.log(2);                        
                        // // console.log(2);)
                            this.hasFirstDataShown = true;
                            const msg = "File added successfully";
                            this.openSnackBar(msg, "OK");
                            return this.onCancelFrm();
                        // } else {
                    } else if (key == "message" && value != "") {
                        // if (key == 'status' && value == 'failed') {
                        // // console.log(e);
                        // if (key == 'message' && value != '') {
                        alert(key + "=" + value);
                        if (value == "Data Created") {
                            value = "File added successfully";
                            this.openSnackBar(value, "OK");
                        } else {
                            this.openSnackBar(value, "OK");
                        }
                        this.onCancelFrm();

                        // this.addCoursesForm.reset();
                        // window.location.reload();
                        // }
                    }
                });
                this.keyValuePair = this.makeKeyValuepair(this.categories);
            });
    }
    getMonthlyTimestamp() {
        let ms = this.date.getTime();
        var d = new Date(ms),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        return [year, month, day].join(" -");
    }

    onCancelFrm() {
        this.dialog.closeAll();
        this.addCoursesForm.reset();
        this.editCoursesForm.reset();
        this.hasFirstDataShown = true;
    }

    onFileSelect(event: any) {
        if (event.target.files.length > 0) {
            // // console.log(h);
            const fileUrl = event.target.files[0];
            // // console.log(l);
            this.addCoursesForm.get("fileUrl").setValue(fileUrl);
            this.file = fileUrl;
        }
    }

    reportOnChangeVal(selectedCategory): void {
        this.selectedCategory = selectedCategory;
    }
    private handleError(error: Error): void {
        this.openSnackBar(error.message, "OK");
        // this.notify.update(error.message, 'error');
    }

    numericOnly(event): boolean {
        const patt = /^([0-9])$/;
        const result = patt.test(event.key);
        return result;
    }

    isNullOrEmpty(event, title) {
        this.titleCurrentValue = title;
        if (event.keyCode === 32 && title.length + 1 === 1) {
            const patt = /^\S+/;
            const result = patt.test(event.key);
            return result;
        } else {
            return event.key;
        }
    }

    makeKeyValuepair(objArray) {
        const customKeyVal = {};
        for (let single of objArray) {
            customKeyVal[single.value] = single.label;
        }
        return customKeyVal;
    }

    nextPage() {
        this.page++;
        if (this.currentCategory === "all" && this.searchTerm == "") {
            this.AllCategoriesResourceCenter();
        } else if (this.currentCategory != "all" && this.searchTerm == "") {
            this.courseByIdfromResource();
        } else if (this.currentCategory == "all" && this.searchTerm != "") {
            this.hasFirstDataShown = false;
            this.filterCoursesByTerm(this.page);
        } else if (this.currentCategory != "all" && this.searchTerm != "") {
            this.hasFirstDataShown = false;
            this.searchCourseByCategory(this.page);
        }
    }

    prevPage() {
        this.page--;
        if (this.currentCategory === "all" && this.searchTerm == "") {
            this.AllCategoriesResourceCenter();
        } else if (this.currentCategory != "all" && this.searchTerm == "") {
            this.courseByIdfromResource();
        } else if (this.currentCategory == "all" && this.searchTerm != "") {
            this.hasFirstDataShown = false;

            this.filterCoursesByTerm(this.page);
        } else if (this.currentCategory != "all" && this.searchTerm != "") {
            this.hasFirstDataShown = false;

            this.searchCourseByCategory(this.page);
        }
    }
}
