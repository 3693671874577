import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import {
    debounceTime,
    finalize,
    map,
    startWith,
    switchMap,
    tap,
} from "rxjs/operators";
import { MatSort } from "@angular/material/sort";
import { SelectionModel } from "@angular/cdk/collections";
import { Observable, Subject } from "rxjs";
import { fuseAnimations } from "@fuse/animations";
import { MatTableDataSource } from "@angular/material/table";
import { StorageService } from "../../../common/service/storage.service";
import { FormBuilder, FormControl } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { UnassignService } from "./unassign.service";
import { HttpClient } from "@angular/common/http";
// import {AngularFirestore} from '@angular/fire/firestore';
import { PatientService } from "../../patient/patient.service";
import { Patient } from "../../patient/patient";
import { Cassette } from "../cassette";
import { StatusService } from "../../status/status.service";
import * as moment from "moment";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DialogUtil } from "../../../../util/DialogUtil";
import { MatDialog } from "@angular/material/dialog";
import * as copy from "copy-to-clipboard";
import { BaseComponent } from "../../../base/base.component";
import { ConfigService } from "../../../common/config/config.service";
import { Globals } from "../../../common/global";
import { ApiService } from "../../../common/service/api.service";
import { TextUtils } from "../../../../util/TextUtils";
import { CookieService } from "ngx-cookie-service";
const timeZone = require("moment-timezone");

export interface State {
    flag: string;
    name: string;
    population: string;
}

@Component({
    selector: "unassign-table",
    templateUrl: "./unassign.component.html",
    styleUrls: ["./unassign.component.scss"],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class UnassignComponent
    extends BaseComponent
    implements OnInit, OnDestroy
{
    ELEMENT_DATA: Cassette[] = [];
    selectedPatient: string;
    dataSource = new MatTableDataSource<Cassette>();
    selection = new SelectionModel<Cassette>(true, []);
    unassigncassetteData: Cassette[] = [];
    stateCtrl = new FormControl();
    urlData = "";
    HASH = "#";
    enableAssign = false;
    columns = [
        {
            columnDef: "Sr",
            header: "Sr.",
            cell: (element: Cassette, index: number) =>
                `${this.page * 10 + index + 1}`,
        },
        {
            columnDef: "Barcode",
            header: "Barcode",
            cell: (element: Cassette, index: number) => `${element.barcode}`,
        },
        {
            columnDef: "Type",
            header: "Type",
            cell: (element: Cassette, index: number) => `${element.type}`,
        },
        {
            columnDef: "Load No",
            header: "Load No",
            cell: (element: Cassette, index: number) => `${element.loadNo}`,
        },
        {
            columnDef: "Cycle No",
            header: "Cycle No",
            cell: (element: Cassette, index: number) => `${element.cycleNo}`,
        },
        {
            columnDef: "Result",
            header: "Result",
            cell: (element: Cassette, index: number) => `${element.platform}`,
            textColor: (element: Cassette, index: number) =>
                `${this.getLoadColor(element)}`,
        },
        {
            columnDef: "Date",
            header: "Date|Time",
            cell: (element: Cassette, index: number) =>
                `${this.changeDate(element.dateTimeNum)}`,
        },
        // { columnDef: 'Date', header: 'Date', cell: (element: Cassette, index: number) => `${element.date}` },
        // { columnDef: 'Time', header: 'Time', cell: (element: Cassette, index: number) => `${element.time}` },

        {
            columnDef: "BD",
            header: "BD",
            cell: (element: Cassette, index: number) =>
                `${element.barcodeBd ? element.barcodeBd : ""}`,
            textColor: (element: Cassette, index: number) =>
                `${this.getSingleLoadColor(element.bd_status)}`,
        },
        {
            columnDef: "BI",
            header: "BI(Spore Test)",
            cell: (element: Cassette, index: number) =>
                `${element.barcodeBi ? element.barcodeBi : ""}`,
            textColor: (element: Cassette, index: number) =>
                `${this.getSingleLoadColor(element.bi_status)}`,
        },
        {
            columnDef: "EI",
            header: "PCD",
            cell: (element: Cassette, index: number) =>
                `${element.barcodeEi ? element.barcodeEi : ""}`,
            textColor: (element: Cassette, index: number) =>
                `${this.getSingleLoadColor(element.ei_status)}`,
        },

        {
            columnDef: "StatusCassette",
            header: "Status",
            cell: (element: Cassette, index: number) => `${element.status}`,
        },
        // `${this.page * 10 + index + 1}`
        {
            columnDef: "Remove",
            header: "Remove",
            cell: (element: Cassette, index: number) =>
                `${this.page * 10 + index + 1}`,
        },
    ];
    displayedColumns = this.columns.map((c) => c.columnDef);

    // @ViewChild(MatPaginator, {static: true})
    // paginator: MatPaginator;
    @ViewChild("input", { static: true }) input: any;

    @ViewChild("filter", { static: true })
    filter: ElementRef;
    isLoading = false;
    errorMsg: string;

    @ViewChild(MatSort, { static: true })
    sort: MatSort;

    page = 0;

    patient: Patient;
    barcode: string;
    searchText: string;
    map = new Map<string, Cassette>();
    isEnterPressed = false;
    isAssignedPressed = false;
    platform = "web";
    selected: any;
    // Private
    private _unsubscribeAll: Subject<any>;
    itemsObservable: Observable<Cassette[]>;
    result: string;
    status: string;
    @ViewChild("alertDialog") alertDialog!: TemplateRef<any>;
    alertMessage: string;
    constAlertMessage: string;

    constructor(
        private userService: StorageService,
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private paginationService: UnassignService,
        private patientService: PatientService,
        private changeDetectorRefs: ChangeDetectorRef,
        private http: HttpClient,
        private snackBar: MatSnackBar,
        private statusService: StatusService,
        // private afs: AngularFirestore,
        public dialog: MatDialog,
        private configService: ConfigService,
        private globals: Globals,
        private apiService: ApiService,
        private cookieService: CookieService
    ) {
        super(configService, globals);
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    @HostListener("document:keydown", ["$event"])
    handleKeyboardEven(event: KeyboardEvent) {
        const key = event.key;
        if (key === "Backspace" || key === "Delete") {
            this.selectedPatient = null;
        }
    }

    @HostListener("document:keypress", ["$event"])
    handleKeyboardEvent(event: KeyboardEvent) {
        const key = event.key;
        if (
            event.key.includes("Enter") &&
            event.code.includes("Enter") &&
            document.activeElement.id.includes("Barcode")
        ) {
            if (this.isAssignedPressed) {
                return;
            }
            this.isEnterPressed = true;
            if (this.barcode.length > 0) {
                this.selectBarcode();
            }
        } else {
            this.isEnterPressed = false;
        }
    }

    // added for clear input and refresh data
    clearInputSearch() {
        this.barcode = "";
    }

    ngOnInit(): void {
        const lab_id = this.userService.getCookie("lab_id");
        if (lab_id) {
            this.input.nativeElement.focus();
            this.urlData = this.route.snapshot.queryParams.barcode;
            // // console.log(a);
            if (this.urlData !== "" && this.urlData !== undefined) {
                this.barcode = this.urlData;
                this.selectBarcode();
            }
        } else {
            this.logout();
        }
    }

    logout(): void {
        localStorage.clear();
        this.userService.clearCookie();
        this.router.navigate(["/login"]);
    }

    changeDate(date): any {
        // // // console.log(e);
        var timestamp = parseInt(date);
        return (date = this.apiService.getDateStringMomentByTz(timestamp));
    }

    onClickAssign(): void {
        if (this.dataSource.data.length) {
            const dialogUtil = new DialogUtil();

            let customMsg = "Are you sure to unassign ";

            dialogUtil.openConfirmationDialog(
                this.dialog,
                customMsg + this.dataSource.data.length + " Cassette(s)?",
                {
                    onConfirm: () => {
                        this.submit();
                    },
                    onCancel: () => {},
                    onAlways: () => {},
                }
            );
        } else {
            this.openAlert(
                this.alertDialog,
                "There is no cassette for unassignment"
            );
        }
    }

    submit(): void {
        const list: string[] = [];
        this.selection.selected.forEach((element) => {
            if (list.indexOf(element.id) < 0) {
                list.push(element.id);
            }
        });
        if (list.length < 1) {
            this.openSnackBar("Please Select at least one Cassette(s)", "OK");
            return;
        }
        this.assign();
    }

    selectBarcode(): void {
        if (this.barcode === "" || this.barcode === undefined) {
            this.openAlert(this.alertDialog, "Please enter cassette barcode");
            return;
        }
        const value = this.barcode.replace(/\s/g, "");
        let notFoundBarcode = value;
        this.barcode = "";
        let isCassetteAlready = false;
        this.dataSource.data.forEach((cassette) => {
            if (cassette.barcode === value) {
                isCassetteAlready = true;
            }
        });
        if (isCassetteAlready) {
            this.openAlert(this.alertDialog, `${value} Duplicate Barcode.`);
            return;
        } else {
        }

        this.paginationService.getCassetteEntryObserable(value).subscribe(
            (a) => {
                // // console.log(a);
                Object.entries(a).find(([key, value]) => {
                    if (key == "data") {
                        // // console.log(h);
                        if (value && value.length > 0) {
                            const cass: Cassette = value;
                            this.enableAssign = true;
                            const eStatus: CassetteStatus =
                                this.statusService.getCassetteStatus(cass);
                            this.addCassetteDirect(cass);
                        } else if (value.length == 0) {
                            this.openAlert(
                                this.alertDialog,
                                `${notFoundBarcode} - Cassette not found.`
                            );
                        }
                    }
                });
                // subs1.unsubscribe();
            },
            (error) => {
                // dp something
            }
            // () => {// dp something
            // }
        );
        this.barcode = "";
    }

    addCassetteDirect(cass) {
        cass.forEach((element) => {
            cass.isAssignDone = element.is_assigned_to_patient;
            this.result = this.getCassetteResult(element);
            // // console.log(t);

            if (element.cassette_status == "Assigned") {
                if (this.result == "Pending") {
                    this.status = "Used(P)";
                } else {
                    this.status = "Used";
                }
            } else if (element.cassette_status == "Used") {
                if (this.result == "Pending") {
                    this.status = "Used(P)";
                } else {
                    this.status = "Used";
                }
            } else if (
                element.cassette_status != "Used" &&
                element.cassette_status != "Assigned" &&
                element.cassette_status != "Spoiled"
            ) {
                this.status = this.getLoadStatus(element);
            } else if (element.cassette_status == "Spoiled") {
                this.status = "Spoiled";
            }
            // // console.log(t);
            if (this.status == "Used" || this.status == "Used(P)") {
                this.unassigncassetteData.push({
                    assigned_to_department: element.assigned_to_department,
                    barcode: element.barcode,
                    added: undefined,
                    startedBy: undefined,
                    assigned: undefined,
                    updated: undefined,
                    spoiled: undefined,
                    comment: "",
                    receiverName: "",
                    departmentName: "",
                    department: undefined,
                    print_title: element.print_title,
                    barcodeBd: element.bd_barcode,
                    barcodeBi: element.bi_barcode,
                    barcodeEi: element.ei_barcode,
                    machineName: "",
                    status: this.status,
                    bd_status: element.bd_status,
                    bi_status: element.bi_status,
                    ei_status: element.ei_status,
                    isAssignDone: element.is_assigned_to_patient,
                    unassigned_by: element.unassigned_by,
                    isSpoiled: element.is_spoiled,
                    type: element.cassette_type,
                    version: 0,
                    cycleNo: element.cycle_number,
                    loadNo: element.load_number,
                    program: "",
                    dateTimeNum: element.timestamp,
                    loadIdStr: "",
                    machineIdStr: "",
                    plateform: "",
                    platform: this.result,
                    verified: "",
                    createdUser: "",
                    patientId: "",
                    patientName: "",
                    cassetteID: "",
                    updatedBy: "",
                    time: "",
                    date: element.timestamp,
                    id: "",
                    docId: "",
                });
            } else {
                this.openAlert(
                    this.alertDialog,
                    `${element.barcode} - Cassette current status is ${this.status}`
                );
            }
        });

        this.dataSource.data = this.unassigncassetteData;
        this.dataSource.filter = "";
        this.selection.toggle(cass);
    }

    assign(): void {
        this.isAssignedPressed = true;
        const time = new Date().getTime();
        const barcodes = [];
        const updateStatus = [];
        const statusBybarcode = [];
        this.unassigncassetteData.forEach((element) => {
            // // // console.log(t);
            // barcodes.push(element.barcode);

            // // console.log(s);
            if (element.status == "Used(P)") {
                updateStatus.push("PCD Pass");
                statusBybarcode.push({
                    barcode: element.barcode,
                    status: "PCD Pass",
                });
            } else if (element.status == "Used") {
                updateStatus.push("Ready");
                statusBybarcode.push({
                    barcode: element.barcode,
                    status: "Ready",
                });
            }
        });

        // // console.log(e);
        let i = 0;
        statusBybarcode.forEach((item) => {
            const time = new Date().getTime();
            // console.log( );
            const updateData = {
                barcode: item.barcode,
                update_data: {
                    cassette_status: item.status,
                    is_assigned_to_patient: false,
                    patient: {
                        id: "",
                        name: "",
                        timestamp: "",
                    },
                    patient_assigned_by: {
                        id: "",
                        name: "",
                        timestamp: "",
                    },
                    timestamp: time.toString(),
                    patient_unassigned_by: {
                        id: this.userService.getCookie("email"),
                        name: this.userService.getCookie("name"),
                        timestamp: time.toString(),
                    },
                },
            };
            // // console.log(a);
            this.paginationService.unassignSets(updateData).subscribe((a) => {
                // // console.log(a);
            });
        });

        // this.openSnackBar(('Sets(s) unassigned Successfully.'), 'OK');
        this.selection.clear();
        this.selectedPatient = "";
        while (this.dataSource.data.length > 0) {
            this.dataSource.data.pop();
        }
        this.dataSource.filter = "";

        this.isAssignedPressed = false;
        this.openSnackBar("Cassette(s) unassigned successfully.", "OK");
        // window.alert("Set unassigned from department successfully");
        this.barcode = "";
    }

    showAlert(message): void {
        const dialogUtil = new DialogUtil();
        dialogUtil.openAlertDialog(this.dialog, message, {
            onConfirm: () => {},
            onCancel: () => {},
            onAlways: () => {},
        });
    }

    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 2000,
        });
    }
    //
    // updateRow(row): any {
    //     row.date = moment(Math.abs(row.dateTimeNum)).format('DD-MM-YYYY');
    //     row.time = moment(Math.abs(row.dateTimeNum)).format('HH:mm');
    // }

    updateRow(row): any {
        row.date = timeZone(Math.abs(row.dateTimeNum))
            .tz(this.userService.getCookie("defaultTz"))
            .format("DD-MM-YYYY");
        row.time = timeZone(Math.abs(row.dateTimeNum))
            .tz(this.userService.getCookie("defaultTz"))
            .format("HH:mm");
        // row.time = moment(Math.abs(row.dateTimeNum)).format('HH:mm');
    }

    nextPage(): void {
        // this.paginationService.next();
    }

    prevPage(): void {
        // this.paginationService.prev();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    displayFn(patient: Patient) {
        if (patient) {
            return patient.first_name;
        }
    }

    getLoadStatus(load: Cassette): string {
        return this.statusService.getCassetteStatus(load);
    }

    getCassetteResult(load: Cassette): string {
        return this.statusService.getMessage(
            this.statusService.getStatusNew(
                load.bd_status,
                load.bi_status,
                load.ei_status
            )
        );
    }

    getLoadColor(load: Cassette): string {
        return this.statusService.getColor(
            this.statusService.getStatusNew(
                load.bd_status,
                load.bi_status,
                load.ei_status
            )
        );
    }

    getSingleLoadColor(load: string): string {
        if (load) {
            return this.statusService.getColor(
                this.statusService.getStatus(load)
            );
        } else {
            return "#111111";
        }
    }

    // remove row from the to be assigned table
    remove(id) {
        const index = id - 1; // index starts from 0
        // const index = this.dataSource.data.indexOf(id);  // got index
        this.ELEMENT_DATA = this.dataSource.data.splice(index, 1); // what id to delete and which one to delete
        this.dataSource = new MatTableDataSource(this.dataSource.data); // refresh the data
        // removing from the selection list
        this.selection.selected.splice(index, 1);
    }

    openAlert(ref: TemplateRef<any>, message: string, constMessage?: string) {
        this.alertMessage = message;
        this.constAlertMessage = constMessage;
        this.dialog.open(ref, {
            width: "420px",
            height: "auto",
            data: { message },
            disableClose: true,
            panelClass: "simple-alert-dialog",
            position: { top: "0%", left: "40%" },
        });
    }

    closeAlertDialog() {
        this.dialog.closeAll();
    }
}
