import {
    AfterViewInit,
    Component,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { fromEvent, merge, Observable, Subject } from "rxjs";
import { fuseAnimations } from "@fuse/animations";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { CassetteListService } from "../list/cassette-list.service";
import { AssignService } from "../assign/assign.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { StatusService } from "../../status/status.service";
import { Cassette } from "../cassette";
import { CookieService } from "ngx-cookie-service";
import { StorageService } from "app/main/module/common/service/storage.service";
import { ApiService } from "../../../common/service/api.service";
import { Globals } from "../../../common/global";
import { DialogUtil } from "../../../../util/DialogUtil";
import { Location } from "@angular/common";

import { I } from "@angular/cdk/keycodes";
// import { Cassette } from '../cassette.module';

const timeZone = require("moment-timezone");
@Component({
    selector: "app-viewcassettedetails",
    templateUrl: "./viewcassettedetails.component.html",
    styleUrls: ["./viewcassettedetails.component.scss"],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class ViewcassettedetailsComponent implements AfterViewInit, OnInit {
    dataSource = new MatTableDataSource<any>();
    displayedColumns: string[] = ["sr", "name", "code", "quantity"];
    urlData = "";
    viewcassetteData: any;
    barcode = "";
    cycleNo = "";
    name = "";
    id = "";
    time = "";
    type = "";
    program = "";
    loadNo: number;
    machineName = "";
    status = "";
    result = " ";
    barcodeBi: any;
    barcodeEi: any;
    barcodeBd: any;
    isAssignDone: boolean;
    statusBi: string;
    statusEi: string;
    statusBd: string;
    date = new Date();
    assignedBy = "";
    assignedOn: any;
    status1 = "";
    spoiledTime = "";
    spoiledName = "";
    addedName = "";
    addedOn = "";
    processedBy = "";
    processedOn = "";
    canAssignIncubator: boolean;

    cstObservableData: Observable<any>;
    assignedDepartment: any;
    asignedDepartmentname: any;
    receivedBy: any;
    quantity: any;
    instrumentsName: any;
    instruments: any = [];
    departments: any;
    categories: any;
    subcategories: any;
    displayName: any;
    comment: any;
    verified: string;
    verifiedBy: any;
    verifiedOn: any;
    href: string;
    currentPageURL: string;
    getbarcodefromParam: string;
    errorMessage: any;
    cassetteDetail: Array<any>;
    loadedcassetteDatadetail: Cassette[] = [];
    can_assign_if_incubator_pending: any;
    assignedpatientName: any;
    assignedpatientId: any;
    isAssignedPatientDone: boolean;
    assignedByPatient: any;
    assignedbypatientOn: any;
    role: string;
    hasFirstDataShown: boolean = false;
    updatedStatus: string;
    enableAssign = false;
    @ViewChild("refDialogUnassignPatient")
    refDialogUnassignPatient!: TemplateRef<any>;
    hide_result_pending_confirmation: any;

    constructor(
        public cassetteListService: CassetteListService,
        private paginationService: AssignService,
        private statusService: StatusService,
        private globals: Globals,
        private snackBar: MatSnackBar,
        private router: Router,
        private userService: StorageService,
        public dialog: MatDialog,
        public apiService: ApiService,
        private location: Location
    ) {}

    ngOnInit(): void {
        this.hasFirstDataShown = false;
        this.loadedcassetteDatadetail = [];
        this.href = this.router.url;
        this.currentPageURL = window.location.href.toString();
        this.getCassetteConfig();
        this.getbarcodefromParam = this.currentPageURL.split("=")[1];
        this.hide_result_pending_confirmation = localStorage.getItem("hide_result_pending_confirmation")
        if (
            this.getbarcodefromParam !== "" &&
            this.getbarcodefromParam !== undefined
        ) {
            this.getAllData(this.getbarcodefromParam);
        } else {
            window.alert("No Data found, try again!");
        }

        this.role = localStorage.getItem("role");
    }

    getAllData(barcode) {
        this.status = "";
        this.cassetteDetail = [];
        // this.isAssignedPatientDone = false
        const cassetteConfig = this.globals.cassetteConfig;
        this.cassetteListService
            .getSetsDetailByBarcode(barcode)
            .subscribe((a) => {
                // // console.log(e);
                Object.entries(a).find(([key, value]) => {
                    if (key == "data") {
                        this.cassetteDetail = value;
                        // // // console.log(l);
                        this.cassetteDetail.forEach((element) => {
                            this.hasFirstDataShown = true;
                            this.instruments = element.instruments;
                            this.dataSource = this.instruments;
                            // // // console.log(s);
                            if (element.verified == false) {
                                this.verified = "No";
                            } else if (element.verified == true) {
                                this.verified = "Yes";
                            }
                            this.viewcassetteData = element;
                            this.barcode = element.barcode;
                            this.program = element.cassette_program;
                            this.cycleNo = element.cycle_number;
                            this.loadNo = element.load_number;
                            this.machineName = element.machine_name;
                            this.departments = element.departments;
                            this.asignedDepartmentname =
                                element.assigned_to_department;
                            this.comment = element.comment
                                ? element.comment
                                : "-";
                            this.categories = element.categories;
                            this.subcategories = element.subcategories;
                            this.displayName = element.display_name;
                            this.status1 = element.cassette_status; // being used as a condition in html page
                            this.barcodeBd = element.bd_barcode
                                ? element.bd_barcode
                                : "-";
                            this.barcodeBi = element.bi_barcode;
                            this.barcodeEi = element.ei_barcode;
                            this.statusBi = element.bi_status;
                            this.statusEi = element.ei_status;
                            this.statusBd = element.bd_status;
                            const cass: Cassette = element;
                            // // // console.log(s);
                            this.isAssignDone =
                                element.is_assigned_to_department;
                            cass.isAssignDone =
                                element.is_assigned_to_department;
                            this.isAssignedPatientDone =
                                element.is_assigned_to_patient;
                            // this.result = this.statusService.getCassetteResult(cass);
                            // // // console.log(t);
                            if (element.department_assigned_by) {
                                this.assignedBy =
                                    element.department_assigned_by.name;
                                this.assignedOn = this.updateDate(
                                    element.department_assigned_by.timestamp
                                );
                                // cass.isAssignDone = element.department_assigned_by.name
                            }

                            this.status1 = element.cassette_status; // being used as a condition in html page
                            this.status =
                                this.statusService.getCassetteStatus(element);
                            if (element.cassette_status == "Assigned") {
                                if (this.result == "Pending") {
                                    this.status = "Used(P)";
                                } else {
                                    this.status = "Used";
                                }
                            } else if (element.cassette_status == "Used") {
                                if (this.result == "Pending") {
                                    this.status = "Used(P)";
                                } else {
                                    this.status = "Used";
                                }
                            } else if (
                                element.cassette_status != "Used" &&
                                element.cassette_status != "Assigned"
                            ) {
                                this.status = this.getLoadStatus(element);
                                // // console.log(s);
                            }

                            // let eStatus: EStatus = this.statusService.getStatusNew(resultData[0].statusBd, resultData[0].statusBi, resultData[0].statusEi);
                            let eStatus: EStatus =
                                this.statusService.getStatusNew(
                                    element.bd_status,
                                    element.bi_status,
                                    element.ei_status
                                );
                            this.result =
                                this.statusService.getCassetteResult(eStatus);

                            //
                            // // console.log(e);

                            if (element.cassette_type == "Implant") {
                                this.type = "Implant-" + element.print_title;
                            } else if (element.print_title) {
                                this.type = element.print_title;
                            } else if (element.cassette_type == "") {
                                this.type = "-";
                            } else {
                                this.type = element.cassette_type;
                            }
                            if (element.added_by) {
                                this.addedName = element.added_by.name;
                                // // // console.log(e);
                                this.addedOn = this.updateDate(
                                    element.added_by.timestamp
                                );
                            } else if (element.created_by) {
                                this.addedName = element.created_by.name;
                                this.addedOn = this.updateDate(
                                    element.created_by.timestamp
                                );
                            }
                            if (element.updated_by) {
                                this.processedBy = element.updated_by.name;
                                this.processedOn = this.updateDate(
                                    element.updated_by.timestamp
                                );
                            }
                            if (element.received_by) {
                                this.receivedBy = element.received_by.name;
                            }
                            if (element.received_by) {
                                // this.assignedBy = element.received_by.name;
                                // // // console.log(y);
                                // // this.assignedOn =  element.assigned.time;
                                // this.assignedOn = this.updateDate(element.received_by.timestamp);
                                // // // console.log(n);
                            }

                            if (element.spoiled_by) {
                                this.spoiledName = element.spoiled_by.name;
                                this.spoiledTime = this.updateDate(
                                    element.spoiled_by.timestamp
                                );
                            }

                            if (
                                element.is_assigned_to_patient != undefined &&
                                element.is_assigned_to_patient == true
                            ) {
                                // // // console.log(");
                                this.assignedpatientName = element.patient.name;
                                this.assignedpatientId = element.patient.id;
                                this.assignedByPatient =
                                    element.patient_assigned_by.name;
                                this.assignedbypatientOn = this.updateDate(
                                    element.patient_assigned_by.timestamp
                                );
                            }
                        });
                    } else {
                        // // console.log(");
                    }
                });
            });

        (err) => {
            this.errorMessage = err.error.message;
            // // console.log(e);
            if (err.status == 401) {
                // // console.log(");,
                alert("Token is invalid or has expired Please relogin.");
                this.logout();
            }
        };
    }

    getLoadStatus(load: Cassette): string {
        return this.statusService.getCassetteStatus(load);
    }

    logout(): void {
        localStorage.clear();
        this.userService.clearCookie();
        // this.globals.cassetteConfig = null;
        this.router.navigate(["/login"]);
        // location.reload();
    }

    back(): void {
        this.location.back();
    }

    getCassetteConfig() {
        this.apiService.getCassetteconfigdata().subscribe((a) => {
            // // console.log(a);
            Object.entries(a).find(([key, value]) => {
                if (key == "data") {
                    value.forEach((element) => {
                        this.can_assign_if_incubator_pending =
                            element.cassette.can_assign_if_incubator_pending;
                        // // console.log(g);
                        Object.entries(element.cassette).find(
                            ([key, value]) => {}
                        );
                    });
                }
            });
        });
    }

    canAssign(cassette): boolean {
        // // // console.log(');
        if (this.statusService.canAssign(cassette)) {
            return cassette.barcode;
        }
    }

    ngAfterViewInit(): void {
        const cassetteConfig = this.globals.cassetteConfig;
    }

    updateDate(value): any {
        const dateTime = timeZone(Math.abs(value))
            .tz(this.userService.getCookie("defaultTz"))
            .format("DD-MM-YYYY | HH:mm");
        return dateTime;
    }

    assignToPatient(barcode) {
        this.router.navigate(["/patientcheckout"], {
            queryParams: { barcode: barcode.trim() },
        });
    }

    unAssignCassette(barcode, status, result) {
        // // console.log(s);
        const time = new Date().getTime();
        if (status == "Assigned" && result == "Pending") {
            this.updatedStatus = "PCD pass";
        } else {
            this.updatedStatus = "Ready";
        }
        const updateData = {
            barcode: barcode,
            update_data: {
                cassette_status: this.updatedStatus,
                is_assigned_to_patient: false,
                patient: {
                    id: "",
                    name: "",
                    timestamp: "",
                },
                patient_assigned_by: {
                    id: "",
                    name: "",
                    timestamp: "",
                },
                timestamp: time.toString(),
                patient_unassigned_by: {
                    id: this.userService.getCookie("email"),
                    name: this.userService.getCookie("name"),
                    timestamp: time.toString(),
                },
            },
        };

        // // console.log(a);
        this.paginationService.assignSets(updateData).subscribe((a) => {
            // // console.log(a);
            if (a) {
                // alert("Set unassigned from department successfully.");
                // this.barcode='';
                // this.hasFirstDataShown = true;
                this.getAllData(barcode);
            }
        });
    }

    spoilCassette(barcode) {
        // // console.log(e);
        const time = new Date().getTime();
        const updateData = {
            barcode: barcode,
            update_data: {
                cassette_status: "Spoiled",
                is_spoiled: true,
                timestamp: time.toString(),
                spoiled_by: {
                    id: this.userService.getCookie("email"),
                    name: this.userService.getCookie("name"),
                    timestamp: time.toString(),
                },
            },
        };
        // // console.log(a);
        this.paginationService.assignSets(updateData).subscribe((a) => {
            // // console.log(a);
            if (a) {
                window.alert("Cassette spoiled successfully");
                // this.barcode='';
                // this.getAllData(barcode);
                this.router.navigate(["/cassette"]);
            }
        });
    }

    showAlert(message): void {
        const dialogUtil = new DialogUtil();
        dialogUtil.openAlertDialog(this.dialog, message, {
            onConfirm: () => {},
            onCancel: () => {},
            onAlways: () => {},
        });
    }

    assignCassette(barcode) {
        if(this.hide_result_pending_confirmation === 'true'){
            this.router.navigate(["/assign"], {
                queryParams: { barcode: barcode.trim() },
            });
        } else {
            if (this.status === "In Progress(P)") {
                const dialogUtil = new DialogUtil();
                dialogUtil.openConfirmationDialog(
                    this.dialog,
                    `Cassette Incubator Result is pending. Do you want to assign cassette with barcode ${barcode}?`,
                    {
                        onConfirm: () => {
                            this.router.navigate(["/assign"], {
                                queryParams: { barcode: barcode.trim() },
                            });
                        },
                        onCancel: () => {
                            this.enableAssign = false;
                        },
                        onAlways: () => {},
                    }
                );
            } else {
                this.router.navigate(["/assign"], {
                    queryParams: { barcode: barcode.trim() },
                });
            }
        }
    }

    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 2000,
        });
    }

    getSingleLoadColor(load: string): string {
        if (load) {
            return this.statusService.getColor(
                this.statusService.getStatus(load)
            );
        } else {
            return "#111111";
        }
    }
    getLoadColor(load: Cassette): string {
        if (load)
            return this.statusService.getColor(
                this.statusService.getStatusNew(
                    load.bd_status,
                    load.bi_status,
                    load.ei_status
                )
            );
        else return null;
    }

    openDialogWithRef(ref: TemplateRef<any>): void {
        this.dialog.open(ref, { width: "400px" });
        // this.router.navigate(['/viewtool']);
    }

    openDialogWithRefUnassignDepartment(ref: TemplateRef<any>, barcode): void {
        // this.hasFirstDataShown = false;
        // // console.log(t);
        const timeout = 3000;
        const dialogRef = this.dialog.open(ref, { width: "400px" });
        this.unAssignCassette(barcode, this.status1, this.result);

        dialogRef.afterOpened().subscribe((gg) => {
            setTimeout(() => {
                dialogRef.close();
                // this.isInitComplete = false;
            }, timeout);
        });

        dialogRef.afterClosed().subscribe((result) => {});
    }

    openConfirmDialogUnAssign(ref: TemplateRef<any>, barcode) {
        const dialogUtil = new DialogUtil();
        dialogUtil.openConfirmationDialog(
            this.dialog,
            `Are you sure to cancel assigned cassette?`,
            {
                onConfirm: () => {
                    console.log("crm");

                    this.openDialogWithRefUnassignDepartment(ref, barcode);
                },
                onCancel: () => {
                    this.enableAssign = false;
                },
                onAlways: () => {},
            }
        );
    }

    // openDialogWithRefUnassignPatient(ref: TemplateRef<any>,barcode): void {
    //     // this.hasFirstDataShown = false;
    //     const timeout = 3000;
    //     const dialogRef = this.dialog.open(ref, { width: '400px' });

    //     // this.router.navigate(['/viewtool']);
    //     // this.unAssignCassetteFromPatient(barcode);

    //     dialogRef.afterOpened().subscribe(gg => {
    //         setTimeout(() => {
    //           dialogRef.close();
    //           // this.isInitComplete = false;
    //         }, timeout)
    //       });

    //       dialogRef.afterClosed().subscribe(result => { })
    // }
}
