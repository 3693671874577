import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { fuseAnimations } from "@fuse/animations";
import * as moment from "moment";
import { Globals } from "../../../common/global";
import { MatTableDataSource } from "@angular/material/table";
import { Observable, Subject } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { TcenterService } from "./tcenter.service";
import { Tcenter } from "./tcenter";
import { StorageService } from "app/main/module/common/service/storage.service";
import { ApiService } from "app/main/module/common/service/api.service";
import { TokenStorageService } from "app/main/module/common/service/token-storage.service";

@Component({
    selector: "tcenter-table",
    templateUrl: "tcenter.component.html",
    styleUrls: ["tcenter.component.scss"],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class TcenterComponent implements OnInit, OnDestroy, AfterViewInit {
    page = 0;
    columns = this.getColumn();
    titleMatIcon = this.getTitleMatIcon();
    title = this.getTitle();
    displayedColumns = this.columns.map((c) => c.columnDef);
    dataSource = new MatTableDataSource<any>();
    length: any;
    perPageData = this.global.perPageData;
    private _unsubscribeAll: Subject<any>;
    itemsObservable: Observable<any[]>;
    protected extraCta = {
        visibility: false,
        text: "Add MSD",
    };
    enableInfoMsg = false;
    hasFirstDataShown = true;
    tcenterData = [];
    category_id: any;
    errorMessage: any;
    hasMoreData: Boolean = false;
    readonly PAGE_SIZE = 10;

    constructor(
        public paginationService: TcenterService,
        private changeDetectorRefs: ChangeDetectorRef,
        private route: ActivatedRoute,
        private router: Router,
        private global: Globals,
        private userService: StorageService,
        private apiService: ApiService,
        public tokenStorage: TokenStorageService
    ) {
        this._unsubscribeAll = new Subject();
    }

    /**
     * On init
     */
    ngOnInit(): void {
        this.page = 0;
        this.getTcenterDetails();
    }

    ngAfterViewInit(): void {
        this.page = 0;
    }

    nextPage(): void {
        // this.paginationService.next();
    }

    prevPage(): void {
        // this.paginationService.prev();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    isOrderByNegative(): boolean {
        return true;
    }

    getColumn(): any[] {
        return [
            {
                columnDef: "Sr",
                header: "Sr.",
                cell: (element: Tcenter, index: number) =>
                    `${this.page * 10 + index + 1}`,
            },
            {
                columnDef: "title",
                header: "Title",
                cell: (element: Tcenter, index: number) =>
                    `${element.display_name}`,
            },
        ];
    }

    getTableName(): string {
        return "tcenter";
    }

    updateRow(row): any {}

    getTitleMatIcon(): string {
        return "model_training";
    }

    getTitle(): string {
        return "Training Center";
    }

    getOrderByColumn(): string {
        return "title";
    }

    openDetail(row) {
        this.router.navigate(["/support/tcenterDetail"], {
            queryParams: { center: row.category_id },
        });
    }

    getTcenterDetails() {
        this.hasFirstDataShown = false;
        const token = this.userService.getCookie("token");
        const labId = this.userService.getCookie("lab_id");
        this.apiService.getLabIdDetail(token, labId).subscribe(
            (a) => {
                console.log(a);
                Object.entries(a).find(([key, value]) => {
                    if (key == "data") {
                        const labData = value;

                        this.tcenterData = labData[0].tcenter;

                        this.dataSource = new MatTableDataSource(
                            labData[0].tcenter
                        );
                        this.hasFirstDataShown = true;
                        this.hasMoreData =
                            this.dataSource._filterData.length ===
                            this.PAGE_SIZE;

                        console.log("tcenter:", this.dataSource);
                    } else {
                        // // // console.log(e);
                    }
                });
            },

            (err) => {
                this.errorMessage = err.error.message;
            }
        );
    }
}
