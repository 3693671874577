import { FuseNavigation } from "@fuse/types";
export const navigation: FuseNavigation[] = [
    {
        id: "applications",
        title: "Applications",
        type: "group",
        children: [
            {
                id: "home",
                title: "Dashboard",
                translate: "NAV.Home",
                type: "item",
                icon: "dashboard",
                url: "/dashboard",
            },
            {
                id: "load",
                title: "Loads",
                type: "item",
                icon: "grain",
                url: "/load",
            },

            {
                id: "cassette",
                title: "Cassettes",
                type: "collapsable",
                icon: "ballot",
                children: [
                    // {
                    //     id: "checkout",
                    //     title: "Prepare Set",
                    //     type: "item",
                    //     url: "/cassette/preparesets",
                    // },

                    // {
                    //     id: "checkout",
                    //     title: "New Prepare Set",
                    //     type: "item",
                    //     url: "/cassette/newpreparesets",
                    // },
                    // {
                    //     id: "checkout",
                    //     title: "View Prepared Sets",
                    //     type: "item",
                    //     url: "/cassette/viewpreparesets",
                    // },
                    {
                        id: "cassettes",
                        title: "List",
                        type: "item",
                        url: "/cassette/cassette",
                    },
                    {
                        id: "checkout",
                        title: "Checkout",
                        type: "item",
                        url: "/cassette/assign",
                    },

                    {
                        id: "unassign",
                        title: "Unassign",
                        type: "item",
                        url: "/cassette/unassign",
                    },
                ],
            },

            {
                id: "patient",
                title: "Patients",
                type: "item",
                icon: "accessibility",
                url: "/patient",
            },

            {
                id: "report",
                title: "Reports",
                type: "item",
                icon: "assessment",
                url: "/report",
            },

            {
                id: "maintenance",
                title: "Maintenance",
                type: "collapsable",
                icon: "art_track",
                children: [
                    {
                        id: "maintenanceHistory",
                        title: "Sterilizer",
                        type: "item",
                        url: "maintenance/maintenanceHistory",
                    },
                    {
                        id: "othermachine",
                        title: "Other Machines",
                        type: "item",
                        url: "maintenance/othermachine",
                    },
                    {
                        id: "waterlineTest",
                        title: "Waterline Test",
                        type: "item",
                        url: "maintenance/waterlineTest",
                    },
                ],
            },
        ],
    },

    // {
    //     id: "department",
    //     title: "Department",
    //     type: "group",
    //     children: [

    //         {
    //             id: "cassettespatient",
    //             title: "Available Sets",
    //             type: "item",
    //             url: "/cassette/patientcheckoutList",
    //         },

    //         {
    //             id: "checkouts",
    //             title: "Checkout",
    //             type: "item",
    //             url: "/cassette/patientcheckout",
    //         },

    //     ],
    // },
    {
        id: "setting",
        title: "Settings",
        type: "group",
        children: [
            {
                id: "team",
                title: "Team",
                type: "item",
                icon: "people",
                url: "/team",
            },
            // {
            //     id: "logs",
            //     title: "Logs",
            //     type: "item",
            //     icon: "ballot",
            //     url: "/logs",
            // },
            // {
            //     id: "questionconsent",
            //     title: "Screening Configuration",
            //     type: "collapsable",
            //     icon: "ballot",
            //     children: [
            //         {
            //             id: "consent",
            //             title: "Consent Form",
            //             type: "item",
            //             url: "/consent",
            //         },
            //         {
            //             id: "question",
            //             title: "Screening Questions",
            //             type: "item",
            //             url: "/question",
            //         },
            //         {
            //             id: "patientResolutionSetting",
            //             title: "Patient Resolution Settings",
            //             type: "item",
            //             url: "/screening/patientResolutionSetting",
            //         },
            //         {
            //             id: "staffResolutionSetting",
            //             title: "Staff Resolution Settings",
            //             type: "item",
            //             url: "/screening/staffResolutionSetting",
            //         },
            //         {
            //             id: "screeningEmailSetting",
            //             title: "Email Settings",
            //             type: "item",
            //             url: "/screening/screeningEmailSetting",
            //         },
            //     ],
            // },
            {
                id: "labconfig",
                title: "Lab Configuration",
                type: "item",
                icon: "group_work",
                url: "/lab",
            },
        ],
    },
    {
        id: "inventory",
        title: "Inventory",
        type: "group",
        children: [
            {
                id: "label",
                title: "Labels Replenishment",
                type: "item",
                icon: "label",
                url: "/label",
            },
        ],
    },
    {
        id: "support",
        title: "Support",
        type: "group",
        children: [
            // {
            //     id: 'Resource Center',
            //     title: 'Resource center',
            //     type: 'item',
            //     icon: 'help_center',
            //     url: '/support/rcenter'
            // },

            {
                id: "academy",
                title: "Resource Center",
                type: "item",
                icon: "school",
                url: "/rcenter/newrc",
            },
            {
                id: "Training Center",
                title: "Training Center",
                type: "item",
                icon: "model_training",
                url: "/support/tcenter",
            },
            {
                id: "faq",
                title: "FAQs",
                type: "item",
                icon: "record_voice_over",
                url: "/support/faq",
            },
            // {
            //     id: 'qrcode',
            //     title: 'Support',
            //     type: 'item',
            //     icon: 'record_voice_over',
            //     url: '/support/qrcode'
            // },

            //         // {
            //         //     id: 'knowledge-base',
            //         //     title: 'Knowledge Base',
            //         //     type: 'item',
            //         //     icon: 'import_contacts',
            //         //     url: '/support/knowledge-base'
            //         // },
            //         // {
            //         //     id: 'chat',
            //         //     title: 'Tickets',
            //         //     type: 'item',
            //         //     icon: 'chat',
            //         //     url: '/support/chat',
            //         // },
        ],
    },
];
