import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { fuseAnimations } from "@fuse/animations";
import { MatTableDataSource } from "@angular/material/table";
import { StorageService } from "../../../common/service/storage.service";
import { FormBuilder, FormControl } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Cassette } from "../cassette";
import { StatusService } from "../../status/status.service";
import * as moment from "moment";
import { CassetteListService } from "./cassette-list.service";
import { ConfigService } from "../../../common/config/config.service";
import { Globals } from "../../../common/global";
// import {BaseComponent} from '../../base/base.component';
import { MatSnackBar } from "@angular/material/snack-bar";
import { BaseListComponent } from "../../../base/base-list.component";
const timeZone = require("moment-timezone");
import { Subscription } from "rxjs";
import { CassetteInfiniteScroll } from "./cassette-infinite-scroll.service";
import { ApiService } from "app/main/module/common/service/api.service";
import { CassetteConfig } from "app/main/module/common/config/cassetteConfig";
import { DialogUtil } from "app/main/util/DialogUtil";
import { MatDialog } from "@angular/material/dialog";
import { element } from "protractor";
// import { CassetteConfig } from 'app/main/module/common/config/cassetteConfig';

@Component({
    selector: "cassette-table",
    templateUrl: "cassette.component.html",
    styleUrls: ["cassette.component.scss"],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class CassetteComponent implements OnInit, OnDestroy, AfterViewInit {
    columns = this.getColumn();
    titleMatIcon = this.getTitleMatIcon();
    title = this.getTitle();
    displayedColumns = this.columns.map((c) => c.columnDef);
    @ViewChild("filter", { static: true })
    filter: ElementRef;

    @ViewChild(MatSort, { static: true })
    sort: MatSort;
    cassette: string;
    perPageData = this.globals.perPageData;
    protected extraCta = {
        visibility: false,
        text: "",
    };

    disableUnassign = true;
    // @ViewChild(MatPaginator, {static: true})
    // paginator: MatPaginator;
    isInitComplete = true;
    isLoading = false;
    isNextPressed = false;
    todayDate: Date = new Date();
    isMoreDataAvailable = true;
    prev: number;
    next: number;
    count: number;
    totalPage: boolean;
    allData: Array<any>;
    errorMessage: any;
    loadedcassetteData: Cassette[] = [];
    cassetteConfigData: CassetteConfig[] = [];
    dataSource = new MatTableDataSource<any>(this.loadedcassetteData);
    dataSourceFilters = new MatTableDataSource(this.loadedcassetteData);
    dataSourceWithPageSize = new MatTableDataSource(this.loadedcassetteData);
    page: number;
    canShowAlertIfIncubatorPending: unknown;
    assignedBy: any;
    assignedOn: any;
    status: string;
    isassigndonefinal: any;
    can_assign_if_incubator_pending: any;
    isLoginFailed: boolean;
    searchText: any;
    result: string;
    isEnterPressed: boolean;
    selectedStatus: string;
    filteredCassette: Array<any>;
    statusMessages = [
        CassetteStatus.AVAILABLE,
        CassetteStatus.Failed,
        CassetteStatus.IN_PROGRESS,
        CassetteStatus.IN_PROGRESS_BI_PENDING,
        CassetteStatus.PATIENTUSED,
        CassetteStatus.PATIENTUSED_FAILED,
        CassetteStatus.PATIENTUSED_IN_PROGRESS,
        CassetteStatus.RESET,
    ];
    enableAssign = false;
    inProgressStatus: string;
    hide_result_pending_confirmation: any;

    constructor(
        private userSevice: StorageService,
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private paginationService: CassetteListService,
        private infiniteScroll: CassetteInfiniteScroll,
        private changeDetectorRefs: ChangeDetectorRef,
        private statusService: StatusService,
        public snackBar: MatSnackBar,
        private configService: ConfigService,
        private apiService: ApiService,
        private globals: Globals,
        public dialog: MatDialog
    ) {
        // super(snackBar, configService, globals);
        this.selectedStatus = "all";
    }
    ngAfterViewInit(): void {}
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        const lab_id = this.userSevice.getCookie("lab_id");
        this.hide_result_pending_confirmation = localStorage.getItem("hide_result_pending_confirmation")

        if (lab_id) {
            this.isInitComplete = true;
            this.dataSource.data = [];
            this.loadedcassetteData = [];
            this.getCassetteConfig();
            this.isLoading = true;
            this.prev = 0;
            this.next = 1;
            this.page = 0;
            this.count = 1;
            this.filteredCassetteData(1);
        } else {
            this.logout();
        }
        console.log(this.selectedStatus);
    }

    getAllData(page) {
        this.isInitComplete = true;
        this.totalPage = false;
        this.paginationService.getAllCassetteData(page, true).subscribe(
            (a) => {
                Object.entries(a).find(([key, value]) => {
                    if (key == "message" && value == "No data Found") {
                        this.totalPage = true;
                    }

                    if (key == "data") {
                        this.isInitComplete = false;
                        this.allData = value;

                        this.isassigndonefinal = "";
                        this.allData.forEach((element) => {
                            this.isLoading = false;

                            // console.log(element);

                            const cass: Cassette = element;

                            this.result = this.getCassetteResult(element);
                            // // console.log(t);
                            if (element.cassette_status == "Assigned") {
                                if (this.result == "Pending") {
                                    this.status = "Used(P)";
                                } else {
                                    this.status = "Used";
                                }
                            } else if (element.cassette_status == "Used") {
                                if (this.result == "Pending") {
                                    this.status = "Used(P)";
                                } else {
                                    this.status = "Used";
                                }
                            } else if (
                                element.cassette_status != "Used" &&
                                element.cassette_status != "Assigned" &&
                                element.cassette_status != "Spoiled"
                            ) {
                                this.status = this.getLoadStatus(element);
                            } else if (element.cassette_status == "Spoiled") {
                                this.status = "Spoiled";
                            }

                            this.loadedcassetteData.push({
                                assigned_to_department:
                                    element.assigned_to_department,
                                barcode: element.barcode,
                                added: element.added_by,
                                startedBy: element.created_by,
                                assigned: element.assigned_by,
                                updated: element.updated_by,
                                spoiled: element.spoiled_by,
                                comment: "",
                                receiverName: "",
                                departmentName: "",
                                department: undefined,
                                print_title: element.print_title,
                                barcodeBd: element.bd_barcode,
                                barcodeBi: element.bi_barcode,
                                barcodeEi: element.ei_barcode,
                                machineName: "",
                                status: this.status,
                                bd_status: element.bd_status,
                                bi_status: element.bi_status,
                                ei_status: element.ei_status,
                                isAssignDone: element.is_assigned_to_patient,
                                unassigned_by: element.unassigned_by,
                                isSpoiled: element.is_spoiled,
                                type: element.cassette_type,
                                version: 0,
                                cycleNo: element.cycle_number,
                                loadNo: element.load_number,
                                program: element.cassette_program,
                                dateTimeNum: element.timestamp,
                                loadIdStr: "",
                                machineIdStr: "",
                                plateform: "",
                                platform: this.result,
                                verified: "",
                                createdUser: "",
                                patientId: "",
                                patientName: "",
                                cassetteID: "",
                                updatedBy: "",
                                time: "",
                                date: element.timestamp,
                                id: "",
                                docId: "",
                            });
                        });
                    } else {
                        // // // console.log(e);
                    }
                });
                //  // // console.log(a);

                // this.loadedcassetteData.sort((a, b) =>
                //     a.status.localeCompare(b.status)
                // );
                this.dataSource.data = this.loadedcassetteData;
                //  // // console.log(a);
            },

            (err) => {
                this.errorMessage = err.error.message;
                // // console.log(e);
                if (err.status == 401) {
                    alert("Token is invalid or has expired Please relogin");
                    this.logout();
                }
            }
        );
    }
    logout(): void {
        localStorage.clear();
        this.userSevice.clearCookie();
        // this.globals.cassetteConfig = null;
        this.router.navigate(["/login"]);
        // location.reload();
    }

    changeDate(date): any {
        // // // console.log(e);
        var timestamp = parseInt(date);
        return (date = this.apiService.getDateStringMomentByTz(timestamp));
    }

    scrollToBottom(): void {
        if (this.isNextPressed) {
            this.isNextPressed = false;
            const table = document.querySelector("mat-table");
            table.scrollBy({
                behavior: "smooth",
                left: 0,
                top: table.scrollHeight,
            });
        }
    }

    ngAfterViewChecked() {
        // your code to update the model
        // this.changeDetectorRefs.detectChanges();
    }
    isOrderByNegative(): boolean {
        return true;
    }

    getRowBackgroundColor(element): string {
        return "#FFFFFF";
    }

    canAssign(cassette): boolean {
        if (this.statusService.canAssign(cassette)) {
            return cassette.barcode;
        }
    }

    onNextPress() {
        this.isLoading = true;
        this.isNextPressed = true;
        this.count = this.count + 1;
        this.next = this.prev + 1;
        this.prev = this.next - 1;
        this.next++;
        this.prev++;
        this.filteredCassetteData(this.next);
        // // console.log(v);
    }

    getColumn(): any[] {
        return [
            {
                columnDef: "SrCassette",
                header: "Sr.",
                cell: (element: Cassette, index: number) =>
                    `${this.page * 10 + index + 1}`,
            },
            {
                columnDef: "BarcodeCassette",
                header: "Barcode",
                cell: (element: Cassette, index: number) =>
                    `${element.barcode}`,
                textColor: (element: Cassette, index: number) =>
                    `${this.getBarcodeColor(element)}`,
            },
            {
                columnDef: "type2",
                header: "Type",
                cell: (element: Cassette, index: number) =>
                    `${element.type ? element.type : "-"}`,
            },
            // { columnDef: 'Load No', header: 'Load No', cell: (element: Cassette, index: number) => `${element.loadNo}` },
            {
                columnDef: "Cycle No",
                header: "Cycle No",
                cell: (element: Cassette, index: number) =>
                    `${element.cycleNo}`,
            },
            {
                columnDef: "ResultCassette",
                header: "Result",
                cell: (element: Cassette, index: number) =>
                    `${element.platform}`,
                textColor: (element: Cassette, index: number) =>
                    `${this.getLoadColor(element)}`,
            },
            {
                columnDef: "Date",
                header: "Date|Time",
                cell: (element: Cassette, index: number) =>
                    `${this.changeDate(element.dateTimeNum)}`,
            },
            // { columnDef: 'timeCassette', header: 'Time', cell: (element: Cassette, index: number) => `${element.time}` },
            {
                columnDef: "BD",
                header: "BD",
                cell: (element: Cassette, index: number) =>
                    `${element.barcodeBd ? element.barcodeBd : "-"}`,
                textColor: (element: Cassette, index: number) =>
                    `${this.getSingleLoadColor(element.bd_status)}`,
            },
            {
                columnDef: "BI",
                header: "BI(Spore Test)",
                cell: (element: Cassette, index: number) =>
                    `${element.barcodeBi ? element.barcodeBi : "-"}`,
                textColor: (element: Cassette, index: number) =>
                    `${this.getSingleLoadColor(element.bi_status)}`,
            },
            {
                columnDef: "EI",
                header: "PCD",
                cell: (element: Cassette, index: number) =>
                    `${element.barcodeEi ? element.barcodeEi : ""}`,
                textColor: (element: Cassette, index: number) =>
                    `${this.getSingleLoadColor(element.ei_status)}`,
            },
            {
                columnDef: "StatusCassette",
                header: "Status",
                cell: (element: Cassette, index: number) =>
                    `${this.getLoadStatus(element)}`,
            },
            {
                columnDef: "CassetteMdetails",
                header: "More Details",
                cell: (element: Cassette, index: number) =>
                    this.loadStatusUsed(
                        this.getLoadStatus(element),
                        element.barcode
                    ),
            },
            {
                columnDef: "actionCassette",
                header: "Checkout",
                cell: (element: Cassette, index: number) =>
                    `${(this.canAssign(element), element.barcode)}`,
            },
            // {
            //     columnDef: 'unassign', header: 'UnAssign',
            //     cell: (element: Cassette, index: number) => this.loadStatusUnassign(this.getLoadStatus(element), element.id)
            // },
        ];
    }

    filteredCassetteData(page) {
        this.isInitComplete = true;
        this.totalPage = false;
        this.paginationService
            .getFilterCassetteByStatus(this.selectedStatus, page)
            .subscribe(
                (res) => {
                    Object.entries(res).find(([key, value]) => {
                        if (key == "message" && value == "No data Found") {
                            this.totalPage = true;
                        }
                        if (key == "data" && value != "") {
                            this.isInitComplete = false;
                            this.filteredCassette = value;

                            this.filteredCassette.forEach((element) => {
                                this.isLoading = false;
                                const cass: Cassette = element;
                                this.result = this.getCassetteResult(element);
                                if (element.cassette_status == "Assigned") {
                                    if (this.result == "Pending") {
                                        this.status = "Used(P)";
                                    } else {
                                        this.status = "Used";
                                    }
                                } else if (element.cassette_status == "Used") {
                                    if (this.result == "Pending") {
                                        this.status = "Used(P)";
                                    } else {
                                        this.status = "Used";
                                    }
                                } else if (
                                    element.cassette_status != "Used" &&
                                    element.cassette_status != "Assigned" &&
                                    element.cassette_status != "Spoiled"
                                ) {
                                    this.status = this.getLoadStatus(element);
                                } else if (
                                    element.cassette_status == "Spoiled"
                                ) {
                                    this.status = "Spoiled";
                                } else if (
                                    element.cassette_status ==
                                        "Incubator_Failed" &&
                                    element.is_assigned_to_patient === true
                                ) {
                                    this.status = "Used(F)";
                                }
                                this.loadedcassetteData.push({
                                    assigned_to_department:
                                        element.assigned_to_department,
                                    barcode: element.barcode,
                                    added: element.added_by,
                                    startedBy: element.created_by,
                                    assigned: element.assigned_by,
                                    updated: element.updated_by,
                                    spoiled: element.spoiled_by,
                                    comment: "",
                                    receiverName: "",
                                    departmentName: "",
                                    department: undefined,
                                    print_title: element.print_title,
                                    barcodeBd: element.bd_barcode,
                                    barcodeBi: element.bi_barcode,
                                    barcodeEi: element.ei_barcode,
                                    machineName: "",
                                    status: this.status,
                                    bd_status: element.bd_status,
                                    bi_status: element.bi_status,
                                    ei_status: element.ei_status,
                                    isAssignDone:
                                        element.is_assigned_to_patient,
                                    unassigned_by: element.unassigned_by,
                                    isSpoiled: element.is_spoiled,
                                    type: element.cassette_type,
                                    version: 0,
                                    cycleNo: element.cycle_number,
                                    loadNo: element.load_number,
                                    program: element.cassette_program,
                                    dateTimeNum: element.timestamp,
                                    loadIdStr: "",
                                    machineIdStr: "",
                                    plateform: "",
                                    platform: this.result,
                                    verified: "",
                                    createdUser: "",
                                    patientId: "",
                                    patientName: "",
                                    cassetteID: "",
                                    updatedBy: "",
                                    time: "",
                                    date: element.timestamp,
                                    id: "",
                                    docId: "",
                                });
                            });

                            console.log(this.loadedcassetteData);

                            this.dataSource.data = this.loadedcassetteData;
                        } else {
                            //@ts-ignore
                            if (res.data.length === 0) {
                                this.isInitComplete = false;
                                this.isLoading = false;

                                //@ts-ignore
                                this.openSnackBar(res?.message, "Ok");
                            }
                        }
                    });
                },
                (err) => {
                    this.openSnackBar(err.message, "Ok");
                }
            );
    }

    filterCassetteByStatus() {
        this.dataSource.data = [];
        this.loadedcassetteData = [];
        this.next = 1;
        this.page = 0;
        this.prev = 0;
        this.filteredCassetteData(this.next);
    }

    getCassetteConfig() {
        this.apiService.getCassetteconfigdata().subscribe((a) => {
            // // console.log(a);
            Object.entries(a).find(([key, value]) => {
                if (key == "data") {
                    value.forEach((element) => {
                        this.can_assign_if_incubator_pending =
                            element.cassette.can_assign_if_incubator_pending;
                        Object.entries(element.cassette).find(
                            ([key, value]) => {}
                        );
                    });
                }
            });
        });
    }
    getCassetteType(element) {
        // // console.log(e);
        if (element.print_title != undefined && element.type != undefined) {
            if (element.type == "Implant") {
                return "I-" + element.print_title;
            } else {
                return element.print_title;
            }
        } else if (element.print_title == "" && element.type == "") {
            return "-";
        }
        //     if(element.print_title != undefined && element.type != undefined){
        //     if(element.type == 'Implant'){
        //         return 'I-' + element.print_title
        //     }
        //     else{
        //         return element.print_title
        //     }
        // }else{
        //     return '-'
        // }
    }

    getBarcodeColor(element) {
        if (element.verified == true) {
            return this.statusService.getBarcodeColor(element.barcode);
            //     // // console.log(d);
            //     if(element.verified == true){
            //     return '##0000FF';
        }
        // else{
        //     return '#000000'
        // }
    }

    loadStatusAvailable(functValue, barcode) {
        if (functValue === "Availbale") {
            return barcode;
        }
    }
    loadStatusUsed(functValue, id) {
        // if(functValue === 'USED'
        // // // console.log(d);
        return id;
        // }
    }
    loadStatusUnassign(functValue, id) {
        if (
            functValue === "USED" ||
            functValue === "Used" ||
            functValue === "Used(P)"
        ) {
            return id;
        }
    }

    getLoadStatus(load: Cassette): string {
        // // // console.log(d);
        return this.statusService.getCassetteStatus(load);
    }

    getCassetteResult(load: Cassette): string {
        return this.statusService.getMessage(
            this.statusService.getStatusNew(
                load.bd_status,
                load.bi_status,
                load.ei_status
            )
        );
    }

    getLoadColor(load: Cassette): string {
        return this.statusService.getColor(
            this.statusService.getStatusNew(
                load.bd_status,
                load.bi_status,
                load.ei_status
            )
        );
    }

    getSingleLoadColor(load: string): string {
        if (load) {
            return this.statusService.getColor(
                this.statusService.getStatus(load)
            );
        } else {
            return "#111111";
        }
    }

    // updateRow(row): any {
    //     row.date = moment(Math.abs(row.dateTimeNum)).format('DD-MM-YYYY');
    //     row.time = moment(Math.abs(row.dateTimeNum)).format('HH:mm');
    // }

    updateRow(row): any {
        row.date = timeZone(Math.abs(row.dateTimeNum))
            .tz(this.userSevice.getCookie("defaultTz"))
            .format("DD-MM-YYYY");
        row.time = timeZone(Math.abs(row.dateTimeNum))
            .tz(this.userSevice.getCookie("defaultTz"))
            .format("HH:mm");
        // row.time = moment(Math.abs(row.dateTimeNum)).format('HH:mm');
    }

    getTitleMatIcon(): string {
        return "ballot";
    }

    getTitle(): string {
        return "Cassettes";
    }

    getSearchText(): string {
        return "Scan/Enter Barcode ";
    }

    assignCassette(barcode, status) {
        console.log(this.inProgressStatus);

        this.status = status;

        console.log(this.status);

        if(this.hide_result_pending_confirmation === "true"){
            this.router.navigate(["/assign"], {
                queryParams: { barcode: barcode.trim() },
            });
        } else {
            if (this.status === "In Progress(P)") {
                const dialogUtil = new DialogUtil();
                dialogUtil.openConfirmationDialog(
                    this.dialog,
                    `Cassette Incubator Result is pending. Do you want to assign cassette with barcode ${barcode}?`,
                    {
                        onConfirm: () => {
                            this.router.navigate(["/assign"], {
                                queryParams: { barcode: barcode.trim() },
                            });
                        },
                        onCancel: () => {
                            this.enableAssign = false;
                        },
                        onAlways: () => {},
                    }
                );
            } else {
                this.router.navigate(["/assign"], {
                    queryParams: { barcode: barcode.trim() },
                });
            }
        }
    }
    viewCassette(barcode) {
        this.router.navigate(["/viewcassettedetails"], {
            queryParams: { barcode: barcode },
        });
    }

    // protected getPaginationService() {
    //     return this.paginationService;
    // }
    // @HostListener('document:keypress', ['$event'])
    // handleKeyboardEvent(event: KeyboardEvent) {
    //     this.handleEnterSearch(event);
    // }
    // changeCheckboxEvent(event, row){

    //     if(event.cheked === true){
    //         this.unassignList.push(row);
    //         // // console.log(t);
    //     }else{
    //         this.unassignList = this.unassignList.filter(element => element !== row);
    //         // // console.log(t);
    //     }
    // }

    nextPage(): void {
        // this.isLoading = true;
        // this.isNextPressed = true;
        // this.infiniteScroll.more();
    }

    ngOnDestroy(): void {
        // // // console.log(');
        // this.subscription.unsubscribe();
        // CassetteComponent.oldData = this.dataSource.data
        // CassetteComponent.cursor = this.infiniteScroll.getCursor()
    }

    // need to uncomment and implement

    onClickSearch(searchText) {
        this.isInitComplete = true;
        this.searchText = searchText.trim();
        this.isLoading = true;
        // // console.log(t);
        if (this.searchText == "") {
            this.ngOnInit();
        } else {
            this.loadedcassetteData = [];
            this.dataSource.data = [];
            this.allData = [];
            this.paginationService.searchCasset(searchText).subscribe(
                (a) => {
                    // // console.log(a);
                    Object.entries(a).find(([key, value]) => {
                        if (key == "data") {
                            this.isInitComplete = false;
                            this.allData = value;
                            // // console.log(a);
                            this.isassigndonefinal = "";
                            this.allData.forEach((element) => {
                                //  // // console.log(e);
                                this.isLoading = false;
                                this.result = this.getCassetteResult(element);

                                const cass: Cassette = element;

                                if (element.department_assigned_by) {
                                    this.assignedBy =
                                        element.department_assigned_by.name;
                                    // this.assignedOn = this.updateDate(element.department_assigned_by.timestamp);
                                    cass.isAssignDone =
                                        element.department_assigned_by.name;
                                    this.isassigndonefinal = cass.isAssignDone;
                                }
                                if (element.cassette_status == "Assigned") {
                                    if (this.result == "Pending") {
                                        this.status = "Used(P)";
                                    } else {
                                        this.status = "Used";
                                    }
                                } else if (element.cassette_status == "Used") {
                                    if (this.result == "Pending") {
                                        this.status = "Used(P)";
                                    } else {
                                        this.status = "Used";
                                    }
                                } else if (
                                    element.cassette_status != "Used" &&
                                    element.cassette_status != "Assigned" &&
                                    element.cassette_status != "Spoiled"
                                ) {
                                    this.status = this.getLoadStatus(element);
                                } else if (
                                    element.cassette_status == "Spoiled"
                                ) {
                                    this.status = "Spoiled";
                                }
                                this.loadedcassetteData.push({
                                    assigned_to_department:
                                        element.assigned_to_department,
                                    barcode: element.barcode,
                                    added: undefined,
                                    startedBy: undefined,
                                    assigned: undefined,
                                    updated: undefined,
                                    spoiled: undefined,
                                    comment: "",
                                    receiverName: "",
                                    departmentName: "",
                                    department: undefined,
                                    print_title: element.print_title,
                                    barcodeBd: element.bd_barcode,
                                    barcodeBi: element.bi_barcode,
                                    barcodeEi: element.ei_barcode,
                                    machineName: "",
                                    status: this.status,
                                    bd_status: element.bd_status,
                                    bi_status: element.bi_status,
                                    ei_status: element.ei_status,
                                    isAssignDone:
                                        element.is_assigned_to_patient,
                                    unassigned_by: element.unassigned_by,
                                    isSpoiled: element.is_spoiled,
                                    type: element.cassette_type,
                                    version: 0,
                                    cycleNo: element.cycle_number,
                                    loadNo: 0,
                                    program: "",
                                    dateTimeNum: element.timestamp,
                                    loadIdStr: "",
                                    machineIdStr: "",
                                    plateform: "",
                                    platform: this.result,
                                    verified: "",
                                    createdUser: "",
                                    patientId: "",
                                    patientName: "",
                                    cassetteID: "",
                                    updatedBy: "",
                                    time: "",
                                    date: element.timestamp,
                                    id: "",
                                    docId: "",
                                });
                            });
                        } else if (key == "status" && value == "failed") {
                            // else {
                            // // console.log(e);
                            this.isLoading = false;
                            const msg = "No Data Found";
                            this.openSnackBar(msg, "OK");
                            // window.location.reload();
                        }
                    });
                    //  // // console.log(a);
                    this.dataSource.data = this.loadedcassetteData;
                    //  // // console.log(a);
                },
                (err) => {
                    this.errorMessage = err.error.message;
                    // // console.log(e);
                    this.isLoginFailed = true;
                }
            );
        }
    }

    refreshSearch(event) {
        if (event.code === "Backspace" && this.searchText.length === 1) {
            this.searchText = "";
            this.onClickSearch(this.searchText);
        }
    }
    openSnackBar(message: string, action: string): void {
        // this.isLoading = true;
        // this.hasFirstDataShown = true;
        this.snackBar.open(message, action, {
            duration: 1000,
        });
    }

    clearInputSearch() {
        this.searchText = "";
        this.ngOnInit();
    }

    @HostListener("document:keypress", ["$event"])
    handleKeyboardEvent(event: KeyboardEvent) {
        this.handleEnterSearch(event);
    }

    handleEnterSearch(event: KeyboardEvent) {
        if (
            event.key.includes("Enter") &&
            event.code.includes("Enter") &&
            document.activeElement.id.includes("search")
        ) {
            this.isEnterPressed = true;
            if (this.searchText && this.searchText.length > 0) {
                this.onClickSearch(this.searchText);
            }
        } else {
            this.isEnterPressed = false;
        }
    }

    isTextOverflowing(element: HTMLElement): boolean {
        return element.scrollWidth > element.clientWidth;
    }
}
