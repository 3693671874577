import {
    Component,
    OnInit,
    ViewEncapsulation,
    Directive,
    HostListener,
    ViewChild,
    ElementRef,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { FuseConfigService } from "@fuse/services/config.service";
import { fuseAnimations } from "@fuse/animations";
import { AuthenticationService } from "../../common/service/authentication.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { TeamService } from "../../sterilwize/team/team.service";
// import { NgxSpinnerService } from 'ngx-spinner';
import { Globals } from "../../common/global";
import { ConfigService } from "../../common/config/config.service";
import { CookieService } from "ngx-cookie-service";
import { StorageService } from "app/main/module/common/service/storage.service";
import { ApiService } from "../../common/service/api.service";
import {
    allTrue,
    resourceCenter,
} from "../../../../navigation/navigationItemObjects";
import { hasOwnProperty } from "tslint/lib/utils";
// import {getLabCenterList} from '../../../../../../functions/src/api/center/centerList';
import { TokenStorageService } from "../../common/service/token-storage.service";
import { Session } from "protractor";

@Component({
    selector: "login-2",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    isShowProgress = false;
    email = "";
    infoMsg = "";
    msgColor = "";
    color = ["green", "red", "blue"];
    spinner = false;
    userData: any;
    credentialData: any;
    landingPage: string = "";
    navigationData: any;
    navigationType: any;
    isLoginFailed: boolean;
    roles: any;
    isLoggedIn: boolean;
    currentUser: any;
    errorMessage: any;
    labsDataId: Array<any> = [];
    userInfo: Array<any> = [];
    labsDetail: any;
    role: any;
    allConfigurationByrole: any;
    currentRoleData: any;
    can_see_twostepcheckout_screen: any;
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    //
    @HostListener("contextmenu", ["$event"])
    onRightClick(event) {
        event.preventDefault();
    }

    constructor(
        private _fuseConfigService: FuseConfigService,
        private authService: AuthenticationService,
        public snackBar: MatSnackBar,
        private _formBuilder: FormBuilder,
        private router: Router,
        public teamService: TeamService,
        // private ngxSpinnerService: NgxSpinnerService,
        private globals: Globals,
        private configService: ConfigService,
        private cookieService: CookieService,
        private userService: StorageService,
        private apiService: ApiService,
        public tokenStorage: TokenStorageService
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true,
                },
                toolbar: {
                    hidden: true,
                },
                footer: {
                    hidden: true,
                },
                sidepanel: {
                    hidden: true,
                },
            },
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.loginForm = this._formBuilder.group({
            email: ["", [Validators.required, Validators.email]],
            password: ["", Validators.required],
        });
    }

    login(): void {
        localStorage.clear();
        this.userService.clearCookie();

        this.spinner = true;
        this.authService.login(this.loginForm.value).subscribe(
            (data) => {
                if (data.data.length == 0) {
                    this.openSnackBar(data.message, "Close");
                } else {
                    this.tokenStorage.saveToken(data.data[0].token);
                }
                this.spinner = true;
                this.tokenStorage.saveUser(data.data[0]);
                this.isLoginFailed = false;
                this.isLoggedIn = true;
                this.currentUser = this.tokenStorage.getUser();
                this.userService.setCookie("token", this.currentUser.token);
                if (this.currentUser) {
                    this.spinner = false;
                    this.checkFetchedUser(this.currentUser);
                    // console.log(this.currentUser);
                    this.userService.setCookie("name", this.currentUser.name);
                    // this.userService.setCookie(
                    //     "userImage",
                    //     "https://www.w3schools.com/howto/img_avatar.png"
                    // );
                    localStorage.setItem("name", this.currentUser.name);
                } else {
                    // do something else
                    // // // console.log(");
                }
            },
            (err) => {
                this.errorMessage = err.error.message;
                // // console.log(e);
                //   if(err.status == 401){
                //     // // // console.log(");,
                //     alert('Token is invalid or has expired Please relogin')
                //     this.logout()
                //   }
                if (err.status != 200) {
                    this.spinner = false;
                    if (
                        this.errorMessage == undefined ||
                        this.errorMessage == "undefined"
                    ) {
                        this.openSnackBar(
                            "Please enter valid credential",
                            "Close"
                        );
                    } else {
                        this.openSnackBar(this.errorMessage, "Close");
                    }
                }
                this.isLoginFailed = true;
            }
        );
    }

    logout(): void {
        localStorage.clear();
        this.userService.clearCookie();
        // this.globals.cassetteConfig = null;
        this.router.navigate(["/login"]);
        // location.reload();
    }

    checkFetchedUser(result) {
        this.email = result.email; // setting session storage value
        // // // console.log(l);
        this.userService.setCookie("email", this.email);

        if (result) {
            if (this.email) this.getuserdata();

            // this.router.navigate(['/'+this.landingPage]);
        } else {
            // // // console.log(');
            this.msgColor = this.color[1];
            this.infoMsg = "User not authorized";
            this.openSnackBar("User not authorized", "Close");
        }
    }

    getuserdata() {
        this.spinner = true;

        const token = this.userService.getCookie("token");
        // // // console.log(n);
        const email = this.userService.getCookie("email");

        this.apiService.getAllLabID(token, email).subscribe(
            (a) => {
                // console.log(a);
                Object.entries(a).find(([key, value]) => {
                    // // console.log(e);
                    if (key == "data") {
                        this.labsDataId = value;

                        this.labsDataId.forEach((element) => {
                            localStorage.setItem("labs", element.labs.length);
                            // console.log(element);
                            if (element.labs.length != 0) {
                                element.labs.forEach((userlab) => {
                                    // console.log(userlab);
                                    if (userlab.default_lab == true) {
                                        // // console.log(r);
                                        localStorage.setItem(
                                            "lab_id",
                                            userlab.lab_id
                                        );
                                        this.userService.setCookie(
                                            "lab_id",
                                            userlab.lab_id
                                        );
                                        localStorage.setItem(
                                            "center",
                                            userlab.center
                                        );
                                        this.userService.setCookie(
                                            "center",
                                            userlab.center
                                        );
                                        localStorage.setItem(
                                            "role",
                                            userlab.role
                                        );
                                        this.userService.setCookie(
                                            "role",
                                            userlab.role
                                        );
                                        this.userService.setCookie(
                                            "name",
                                            element.name
                                        );
                                        this.userService.setCookie(
                                            "userImage",
                                            element.profile_pic
                                        );
                                        localStorage.setItem(
                                            "name",
                                            element.name
                                        );

                                        
                                        // this.navigationType = 'all';
                                        // // // console.log(e);

                                        // // // console.log(a);)
                                        // this.router.navigate(['/' + this.landingPage]);
                                        this.getTimeZone();
                                    }
                                });
                            } else {
                                this.spinner = false;
                                window.alert(
                                    "No Lab assigned, Please contact your Administrator for lab assign"
                                );
                            }
                        });
                    } else {
                        // // // console.log(e);
                    }
                });
            },

            (err) => {
                this.errorMessage = err.error.message;
                // // console.log(e);
                if (err.status == 401) {
                    alert("Token is invalid or has expired Please relogin");
                    this.logout();
                }
                this.isLoginFailed = true;
            }
        );
    }

    getROleconfiguration(loginUserRole) {
        this.navigationData = "";
        this.navigationType = "";

        this.apiService.getAllRoleByName(loginUserRole).subscribe(
            (a) => {
                Object.entries(a).find(([key, value]) => {
                    if (key == "data") {
                        this.allConfigurationByrole = value;
                        this.allConfigurationByrole.forEach((element) => {
                            this.currentRoleData = element.access_specifier;
                            for (const [key, value] of Object.entries(
                                this.currentRoleData
                            )) {
                                this.can_see_twostepcheckout_screen =
                                    this.currentRoleData.can_see_twostepcheckout_screen;

                                if (this.role == "doctor") {
                                    this.navigationType = "ot";
                                } else if (
                                    this.can_see_twostepcheckout_screen == true
                                ) {
                                    this.navigationType = "all";
                                } else {
                                    this.navigationType = "usernav";
                                }
                                switch (this.navigationType) {
                                    case "rcenter":
                                        this.navigationData = resourceCenter;
                                        this.landingPage = "rcenter/centers";
                                        break;
                                    case "all":
                                        this.navigationData = allTrue;
                                        this.landingPage = "dashboard";
                                        break;
                                    // case 'usernav':
                                    //     this.navigationData = userTrue;
                                    //     this.landingPage = 'dashboard';
                                    //     break;
                                    // case 'ot':
                                    //     this.navigationData = ot;
                                    //     this.landingPage = '/cassette/patientcheckoutList';
                                    //  break;
                                    default:
                                        this.navigationData = allTrue;
                                        this.landingPage = "dashboard";
                                }

                                // // console.log(e);
                                this.userService.setCookie(
                                    "navigationType",
                                    this.navigationType
                                );
                                this.userService.setCookie(
                                    "pageDynamicConfig",
                                    JSON.stringify(this.navigationData)
                                );

                                this.router.navigate(["/" + this.landingPage]);
                            }
                        });

                        // // // console.log(e);
                    } else {
                    }
                });
            },

            (err) => {
                this.errorMessage = err.error.message;
                // // console.log(e);
                if (err.status == 401) {
                    // // // console.log(");,
                    alert("Token is invalid or has expired Please relogin");
                    this.logout();
                }
            }
        );
    }

    getTimeZone() {
        // // console.log(");
        const token = this.userService.getCookie("token");
        const labId = this.userService.getCookie("lab_id");
        this.apiService.getLabIdDetail(token, labId).subscribe(
            (a) => {
                // console.log(a);
                Object.entries(a).find(([key, value]) => {
                    if (key == "data") {
                        this.labsDetail = value;
                        //  // // console.log(l);
                        const faqDataArr = this.labsDetail[0].faqs;
                        const faqIds = faqDataArr.map((item) => item.faq_id);
                        localStorage.setItem("faqIds", JSON.stringify(faqIds));
                        this.labsDetail.forEach((element) => {
                            // // // console.log(e);
                            this.userService.setCookie(
                                "defaultTz",
                                element.default_time_zone
                            );
                            this.userService.setCookie(
                                "lab_name",
                                element.lab_name
                            );
                            this.userService.setCookie(
                                "lab_logo",
                                element.lab_logo
                            );
                            this.userService.setCookie(
                                "multi_center",
                                element.multi_center
                            );
                            this.role = localStorage.getItem("role");
                            //    // // console.log(e);
                            this.getROleconfiguration(this.role);
                            this.getCassetteConfig();
                            // this.getEmailFromLabConfig();
                        });

                        // });
                    } else {
                        // // // console.log(e);
                    }
                });
            },

            (err) => {
                this.errorMessage = err.error.message;
                // // console.log(e);
                if (err.status == 401) {
                    // // // console.log(");,
                    alert("Token is invalid or has expired Please relogin");
                    this.logout();
                }
                this.isLoginFailed = true;
            }
        );
    }

    getCassetteConfig() {
        this.apiService.getCassetteconfigdata().subscribe((a) => {
            // console.log("Cassette config: ", a);
            Object.entries(a).find(([key, value]) => {
                if (key == "data") {
                    value.forEach((element) => {
                        localStorage.setItem(
                            "can_assign_if_incubator_pending",
                            element.cassette.can_assign_if_incubator_pending
                        );
                        localStorage.setItem(
                            "hide_result_pending_confirmation",element.cassette.hide_result_pending_confirmation
                        )
                        // console.log(element);
                        Object.entries(element.cassette).find(
                            ([key, value]) => {}
                        );
                    });
                }
            });
        });
    }

    // getEmailFromLabConfig() {
    //     this.apiService.getLabconfigdata().subscribe((config) => {
    //         console.log(config);
    //         Object.entries(config).find(([key, value]) => {
    //             // // console.log(e);
    //             if (key == "data") {
    //                 const emailConfig = value;
    //                 // // console.log(s);
    //                 emailConfig.forEach((element) => {
    //                     localStorage.setItem(
    //                         "checkout_email",
    //                         JSON.stringify(element.email.checkout_email)
    //                     );
    //                     localStorage.setItem(
    //                         "enable_lab_checkout_email",
    //                         element.email.enable_lab_checkout_email
    //                     );
    //                     localStorage.setItem(
    //                         "enable_patient_checkout_email",
    //                         element.email.enable_patient_checkout_email
    //                     );
    //                     // return this.barcodeList
    //                     // // console.log(t);
    //                 });

    //                 // // console.log(t);
    //             }
    //             // else {
    //             //     // // console.log(e);
    //             // }
    //         });
    //     });
    // }

    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 3000,
        });
    }
}
