import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'anms-dialog-confirmation',
  templateUrl: 'dialog-confirmation.component.html',
})
export class DialogConfirmationComponent implements OnInit, AfterViewInit {
  message = '';
  hasCancel = true;
  hide_result_pending_confirmation: any

  ngOnInit(): void {
    this.hide_result_pending_confirmation = localStorage.getItem("hide_result_pending_confirmation")
  }
  ngAfterViewInit() {


    if (this.message === 'Incubator Result for the cassette is pending. Do you still want to assign this cassette?'
    ) {
      document.getElementById('elementCancel').innerText = 'No';
      document.getElementById('elementOk').innerText = 'Yes';
    }
    document.getElementById('elementCancel').focus();

  }

  constructor(public dialogRef: MatDialogRef<DialogConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.message = data.message;
    this.hasCancel = data.hasCancel;
  }

  ok() {
    this.data.value = 1;
    this.data.ok = 1;
    this.dialogRef.close(this.data);
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
