import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { StorageService } from "app/main/module/common/service/storage.service";
import { environment } from "environments/environment";
@Injectable()
export class FaqService implements Resolve<any> {
    faqs: any;
    hasFirstDataShown = false;
    onFaqsChanged: BehaviorSubject<any>;
    baseUrl = environment.baseUrl;
    apiUrl = this.baseUrl + "/api/v1/";

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private userService: StorageService
    ) {
        // Set the defaults
        this.onFaqsChanged = new BehaviorSubject({});
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            Promise.all([
                // this.getFaqs()
            ]).then(() => {
                resolve;
            }, reject);
        });
    }

    /**
     * Get faqs
     */

    getFaqData(faqIdsArr) {
        const token = this.userService.getCookie("token");
        const headers = new HttpHeaders().set("Authorization", `${token}`);
        const labId = this.userService.getCookie("lab_id");

        const serializedArray = JSON.stringify(faqIdsArr); // Serialize the array
        const params = new HttpParams().set("ids", serializedArray);

        return this._httpClient.get(this.apiUrl + "faq" + "?" + params, {
            headers,
        });
    }

    searchFaq(faqIdsArr, searchValue) {
        const token = this.userService.getCookie("token");
        const headers = new HttpHeaders().set("Authorization", `${token}`);
        const labId = this.userService.getCookie("lab_id");

        const serializedArray = JSON.stringify(faqIdsArr);
        const params = new HttpParams()
            .set("ids", serializedArray)
            .set("search", searchValue);

        return this._httpClient.get(this.apiUrl + "faq" + "?" + params, {
            headers,
        });
    }
}
