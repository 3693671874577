import {
    Component,
    OnInit,
    OnDestroy,
    ViewEncapsulation,
    ViewChild,
    ElementRef,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { fromEvent, Subject, Subscription } from "rxjs";
import {
    debounceTime,
    distinctUntilChanged,
    finalize,
    shareReplay,
    switchMap,
    takeUntil,
} from "rxjs/operators";

import { FuseUtils } from "@fuse/utils";
import { FaqService } from "./faq.service";
import { Observable } from "rxjs/Observable";
import { Patient } from "../../sterilwize/patient/patient";
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { StorageService } from "../../common/service/storage.service";
import { ApiService } from "../../common/service/api.service";
import { TokenStorageService } from "../../common/service/token-storage.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
    selector: "faq",
    templateUrl: "./faq.component.html",
    styleUrls: ["./faq.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class FaqComponent implements OnInit, OnDestroy {
    faqs: any;
    faqsFiltered: any;
    step: number;
    hasFirstDataShown = false;
    searchInput = new FormControl();
    errorMessage: any;
    faqIds = [];
    faqIdsArr = [];
    faqDataArr: any;
    allfaqDataArr: any;
    faqData: any;
    searchValue: string = "";
    searchInputSubscription: Subscription;
    isLoading: Boolean = false;
    showNoResultsMessage: Boolean = false;

    // Private
    private _unsubscribeAll: Subject<any>;

    @ViewChild("myInput", { static: true }) input: any;

    @ViewChild("myInput", { static: true })
    myInput: ElementRef;
    /**
     * Constructor
     *
     * @param {FaqService} _faqService
     */
    constructor(
        private _faqService: FaqService,
        private userService: StorageService,
        private apiService: ApiService,
        public tokenStorage: TokenStorageService,
        public snackBar: MatSnackBar
    ) {
        // Set the defaults
        this.searchInput = new FormControl("");
        this.step = 0;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.hasFirstDataShown = false;
        const faqIdsJson = localStorage.getItem("faqIds");
        if (faqIdsJson) {
            try {
                this.faqIdsArr = JSON.parse(faqIdsJson);
                if (!Array.isArray(this.faqIdsArr)) {
                    console.error("faqIds is not an array");
                    this.faqIdsArr = [];
                }
            } catch (e) {
                console.error("Error parsing faqIds from localStorage", e);
                this.faqIdsArr = [];
            }
        } else {
            this.faqIdsArr = [];
        }
        // this.getFaqDataDetail();
        this.searchFaqData();
        this.faqsFiltered = this.faqs;
        this._faqService.searchFaq(this.faqIdsArr, "").subscribe(
            (res) => {
                //@ts-ignore
                if (res && res.data) {
                    //@ts-ignore

                    this.faqs = res.data;
                    this.faqsFiltered = this.faqs;
                    this.hasFirstDataShown = true;
                } else {
                    this.faqs = [];
                    this.faqsFiltered = [];
                }
            },
            (err) => {
                console.error("Error fetching initial FAQs:", err);
                this.faqs = [];
                this.faqsFiltered = [];
            }
        );
        console.log(this.faqsFiltered);
    }
    filterItem(value: string) {
        value = value.toUpperCase();
        this.searchFaqData(); // Trigger search

        if (value) {
            this.faqsFiltered = (this.faqs || []).filter((item) => {
                const title = item.title ? item.title.toUpperCase() : "";
                const des = item.des ? item.des.toUpperCase() : "";
                return title.includes(value) || des.includes(value);
            });
        } else {
            this.faqsFiltered = this.faqs;
        }
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Set step
     *
     * @param {number} index
     */
    setStep(index: number): void {
        this.step = index;
    }

    /**
     * Next step
     */
    nextStep(): void {
        this.step++;
    }

    /**
     * Previous step
     */
    prevStep(): void {
        this.step--;
    }

    getFaqDataDetail() {
        this.hasFirstDataShown = false;
        this._faqService.getFaqData(this.faqIdsArr).subscribe(
            (res) => {
                Object.entries(res).find(([key, value]) => {
                    if (key == "data") {
                        this.faqs = value;
                        this.hasFirstDataShown = true;
                    } else {
                    }
                });
            },
            (err) => {
                this.errorMessage = err.error.message;
            }
        );
    }

    searchFaqData() {
        this.searchValue = this.searchInput.value;
        console.log("search value: ", this.searchValue);

        this.hasFirstDataShown = false;

        this.searchInputSubscription = this.searchInput.valueChanges
            .pipe(
                debounceTime(150),
                distinctUntilChanged(),
                switchMap((value: string) => {
                    const trimmedValue = value.trim();
                    if (!trimmedValue) {
                        this.faqsFiltered = this.faqs;
                        this.isLoading = false;
                        return [];
                    }
                    this.isLoading = true;
                    return this._faqService.searchFaq(
                        this.faqIdsArr,
                        trimmedValue
                    );
                })
            )
            .subscribe(
                (res) => {
                    if (res && res.data) {
                        this.faqsFiltered = res.data;
                        this.isLoading = false;
                        this.hasFirstDataShown = true;
                        console.log("Filtered results:", this.faqsFiltered);
                    } else {
                        this.faqsFiltered = [];
                    }
                    if (
                        this.myInput.nativeElement.value.length >= 1 &&
                        this.faqsFiltered.length === 0
                    ) {
                        this.showNoResultsMessage = true;
                    } else {
                        this.showNoResultsMessage = false;
                    }
                },
                (err) => {
                    console.error("Error during search:", err);
                    this.faqsFiltered = [];
                    this.openSnackBar(err.message, "Ok");
                }
            );
    }

    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 3000,
        });
    }
}
