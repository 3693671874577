import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { Observable, Subject } from "rxjs";
import { StorageService } from "../../../common/service/storage.service";
import { FormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { StatusService } from "../../status/status.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ConfigService } from "../../../common/config/config.service";
import { Globals } from "../../../common/global";
import { Cassette, cassetteInfo } from "../../cassette/cassette";
import * as moment from "moment";
import { BaseComponent } from "../../../base/base.component";
import { fuseAnimations } from "../../../../../../@fuse/animations";
import { PatientdetailService } from "./patientdetail.service";

const timeZone = require("moment-timezone");

@Component({
    selector: "app-patientdetail",
    templateUrl: "./patientdetail.component.html",
    styleUrls: ["./patientdetail.component.scss"],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class PatientdetailComponent
    extends BaseComponent
    implements OnInit, OnDestroy, AfterViewInit
{
    page = 0;
    dataLength = 0;
    perPageData = this.globals.perPageData;
    columns = this.getColumn();
    titleMatIcon = this.getTitleMatIcon();
    title = this.getTitle();
    displayedColumns = this.columns.map((c) => c.columnDef);
    dataSource = new MatTableDataSource<any>();

    @ViewChild("filter", { static: true })
    filter: ElementRef;

    @ViewChild(MatSort, { static: true })
    sort: MatSort;
    cassette: string;
    searchText: string;

    // Private
    private _unsubscribeAll: Subject<any>;
    itemsObservable: Observable<any[]>;

    protected extraCta = {
        visibility: false,
        text: "",
    };
    isEnterPressed = false;
    isAssignedPressed = false;
    isLoading = false;
    hasFirstDataShown = false;
    token = "";
    queryParam = {};
    urlPatientId = "";
    firstName = "";
    lastName = "";
    fullName = "";
    enableInfoMsg = false;
    // @ViewChild(MatPaginator, {static: true})
    // paginator: MatPaginator;

    constructor(
        private userSevice: StorageService,
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private paginationService: PatientdetailService,
        private changeDetectorRefs: ChangeDetectorRef,
        private statusService: StatusService,
        public snackBar: MatSnackBar,
        private configService: ConfigService,
        private globals: Globals,
        private dataService: PatientdetailService
    ) {
        super(configService, globals);
        // super();
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        // // console.log(e);
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */

    patientHistory: any;

    ngOnInit(): void {
        this.page = 0;

        this.token = this.route.snapshot.queryParams.token;
        if (this.token) {
            const keyVal = this.globals.decodeToa(this.token).split("##");
            const abKey = keyVal.map((para1) => para1.split("#")[0]);
            const abVal = keyVal.map((para1) => para1.split("#")[1]);
            this.queryParam = abVal.reduce((result, field, index) => {
                result[abKey[index]] = field;
                return result;
            }, {});
        }
        this.urlPatientId = this.queryParam["patient"];
        this.firstName = this.queryParam["firstName"]
            ? this.queryParam["firstName"]
            : "";
        this.lastName = this.queryParam["lastName"]
            ? this.queryParam["lastName"]
            : "";

        this.fullName =
            this.urlPatientId + " " + this.firstName + " " + this.lastName;
        this.paginationService.patientId = this.urlPatientId;

        this.getPatientHistory();
    }

    @HostListener("document:keypress", ["$event"])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (
            event.key.includes("Enter") &&
            event.code.includes("Enter") &&
            document.activeElement.id.includes("cassette")
        ) {
            if (this.isAssignedPressed) {
                return;
            }
            this.isEnterPressed = true;
            if (this.searchText && this.searchText.length > 0) {
                this.onClickSearch();
            }
        } else {
            this.isEnterPressed = false;
        }
    }

    getPatientHistory() {
        this.dataService
            .getPatientHistory(this.urlPatientId)
            .subscribe((response) => {
                Object.entries(response).find(([key, value]) => {
                    if (key == "message" && value == "No data Found") {
                        this.isLoading = false;
                    }

                    if (key == "data") {
                        this.patientHistory = response;
                        this.dataSource.data = response.data;
                        // // console.log(e);
                        // // console.log(e);
                    }

                    if (response.status === "success") {
                        this.isLoading = true;
                    } else {
                        this.isLoading = true;
                        const snackBarRef = this.snackBar.open(
                            "This patient doesn't have any History",
                            "Go Back",
                            {
                                duration: 5000,
                            }
                        );

                        const url = `/patient`;
                        snackBarRef.onAction().subscribe(() => {
                            this.router.navigateByUrl(url);
                        });
                    }
                });
            });
    }

    onClickSearch() {
        // alert('Search Text' + this.searchText);
        this.paginationService.searchedText = this.searchText;
        // this.paginationService.search();
    }

    // ngAfterViewInit(): void {
    //     this.dataSource.filteredData = this.patientHistory.data;
    // }

    ngAfterViewInitViaBase(): void {
        this.page = 0;
        this.paginationService.setTable(
            this.getTableName(),
            this.getOrderByColumn()
        );

        if (!this.isOrderByNegative()) {
            this.paginationService.setPositiveOrderBy();
        }
        // this.paginationService.first();

        this.paginationService.pageObservable.subscribe((pageNumber) => {
            this.page = pageNumber;
            this.changeDetectorRefs.detectChanges();
        });

        this.itemsObservable = this.paginationService.itemsObservable;
        this.itemsObservable.subscribe((data) => {
            // this.datas = data;
            this.dataLength = data.length;
            if (data && data.length > 0) {
                data.forEach((value) => this.updateRow(value));
                this.dataSource.data = data;
                this.hasFirstDataShown = true;
            } else {
                if (this.hasFirstDataShown) {
                    this.openSnackBar("No data available", "OK");
                }
                this.hasFirstDataShown = false;
                this.enableInfoMsg = false;
                setTimeout(() => {
                    this.hasFirstDataShown = true;
                    this.enableInfoMsg = true;
                }, 5000);

                // this.openSnackBar('No data available', 'OK');
            }
            this.isLoading = false;
            // this.filterDataByType(data);
        });
    }

    nextPage(): void {
        if (this.isLoading) {
            return;
        }

        this.isLoading = true;
        // this.paginationService.next();
    }

    prevPage(): void {
        if (this.isLoading) {
            return;
        }
        this.isLoading = true;

        // this.paginationService.prev();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        this.page = 0;
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    isOrderByNegative(): boolean {
        return true;
    }

    getRowBackgroundColor(element): string {
        return "#FFFFFF";
    }

    canAssign(cassette): boolean {
        if (this.statusService.canAssign(cassette)) {
            return cassette.barcode;
        }
    }

    getDate(element) {
        let timestamp = Number(element);

        const date = new Date(timestamp);

        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const formattedDate = `${day}-${month}-${year}`;

        return formattedDate;
    }

    getTime(element) {
        let timestamp = Number(element);

        const time = new Date(timestamp);

        let hours = time.getHours();
        const minutes = time.getMinutes();
        const seconds = time.getSeconds();
        const ampm = hours >= 12 ? "PM" : "AM";

        hours = hours % 12;
        hours = hours ? hours : 12;
        const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
        // const formattedSeconds = seconds < 10 ? "0" + seconds : seconds;

        const formattedTime = `${hours}:${formattedMinutes} ${ampm}`;

        return formattedTime;
    }

    getColumn(): any[] {
        return [
            {
                columnDef: "Sr",
                header: "Sr.",
                cell: (element: cassetteInfo, index: number) =>
                    `${this.page * 10 + index + 1}`,
            },
            {
                columnDef: "Barcode",
                header: "Barcode",
                cell: (element: cassetteInfo, index: number) =>
                    `${element.barcode}`,
            },
            {
                columnDef: "Type",
                header: "Type",
                cell: (element: cassetteInfo, index: number) =>
                    `${element.cassette_type}`,
            },
            {
                columnDef: "Load No",
                header: "Load No",
                cell: (element: cassetteInfo, index: number) =>
                    `${element.load_number}`,
            },
            {
                columnDef: "Cycle No",
                header: "Cycle No",
                cell: (element: cassetteInfo, index: number) =>
                    `${element.cycle_number}`,
            },
            {
                columnDef: "Result",
                header: "Result",
                cell: (element: cassetteInfo, index: number) =>
                    `${this.getCassetteResult(element)}`,
                textColor: (element: cassetteInfo, index: number) =>
                    `${this.getLoadColor(element)}`,
            },
            {
                columnDef: "Date",
                header: "Date",
                cell: (element: cassetteInfo, index: number) =>
                    `${this.getDate(element.timestamp)}`,
            },
            {
                columnDef: "Time",
                header: "Time",
                cell: (element: cassetteInfo, index: number) =>
                    `${this.getTime(element.timestamp)}`,
            },
            {
                columnDef: "BD",
                header: "BD",
                cell: (element: cassetteInfo, index: number) =>
                    `${element.bd_barcode ? element.bd_barcode : ""}`,
                textColor: (element: cassetteInfo, index: number) =>
                    `${this.getSingleLoadColor(element.bd_status)}`,
            },
            {
                columnDef: "BI",
                header: "BI",
                cell: (element: cassetteInfo, index: number) =>
                    `${element.bi_barcode ? element.bi_barcode : ""}`,
                textColor: (element: cassetteInfo, index: number) =>
                    `${this.getSingleLoadColor(element.bi_status)}`,
            },
            {
                columnDef: "EI",
                header: "EI",
                cell: (element: cassetteInfo, index: number) =>
                    `${element.ei_barcode ? element.ei_barcode : ""}`,
                textColor: (element: cassetteInfo, index: number) =>
                    `${this.getSingleLoadColor(element.ei_status)}`,
            },
            {
                columnDef: "Status",
                header: "Status",
                cell: (element: cassetteInfo, index: number) =>
                    `${this.getLoadStatus(element)}`,
            },
            {
                columnDef: "More Details",
                header: "More Details",
                hide: false,
                cell: (element: cassetteInfo, index: number) => null,
            },
            // {columnDef: 'Details', header: 'Details',
            //     cell: (element: Cassette , index: number) => this.loadStatusUsed(this.getLoadStatus(element),element.id)},
            // {columnDef: 'Action', header: 'Action',
            //     cell: (element: Cassette , index: number) => this.canAssign(element)},
        ];
    }

    loadStatusAvailable(functValue, barcode) {
        if (functValue === "Availbale") {
            return barcode;
        }
    }
    loadStatusUsed(functValue, id) {
        // if(functValue === 'USED'){
        return id;
        // }
    }

    getLoadStatus(load: cassetteInfo): string {
        return this.statusService.getCassetteStatusNew(load);
    }

    getCassetteResult(load: cassetteInfo): string {
        return this.statusService.getMessage(
            this.statusService.getStatusNew(
                load.bd_status,
                load.bi_status,
                load.ei_status
            )
        );
    }

    getLoadColor(load: cassetteInfo): string {
        return this.statusService.getColor(
            this.statusService.getStatusNew(
                load.bd_status,
                load.bi_status,
                load.ei_status
            )
        );
    }

    getSingleLoadColor(load: string): string {
        if (load) {
            return this.statusService.getColor(
                this.statusService.getStatus(load)
            );
        } else {
            return "#111111";
        }
    }
    // updateRow(row): any {
    //     row.date = moment(Math.abs(row.dateTimeNum)).format('DD-MM-YYYY');
    //     row.time = moment(Math.abs(row.dateTimeNum)).format('HH:mm');
    // }
    updateRow(row): any {
        row.date = timeZone(Math.abs(row.dateTimeNum))
            .tz(this.userSevice.getCookie("defaultTz"))
            .format("DD-MM-YYYY");
        row.time = timeZone(Math.abs(row.dateTimeNum))
            .tz(this.userSevice.getCookie("defaultTz"))
            .format("HH:mm");
    }

    getOrderByColumn(): string {
        return "dateTimeNum";
    }

    getTableName(): string {
        return "cassette";
    }

    getTitleMatIcon(): string {
        return "accessibility";
    }

    getTitle(): string {
        return "Patient History";
    }

    getSearchText(): string {
        return "Search cassette via barcode";
    }

    assignCassette(barcode) {
        this.router.navigate(["/assign"], {
            queryParams: { barcode: barcode.trim() },
        });
    }
    viewCassette(barcode) {
        console.log(barcode);

        this.router.navigate(["/viewcassettedetails"], {
            queryParams: { barcode: barcode },
        });
    }

    onCassetteClick(barcode: string) {
        return barcode;
    }

    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 3000,
        });
    }
}
