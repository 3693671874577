import {
    AfterViewInit,
    ChangeDetectorRef,
    ElementRef,
    HostListener,
    OnDestroy,
    TemplateRef,
    ViewChild,
} from "@angular/core";
import { MatSort } from "@angular/material/sort";
import "rxjs/add/operator/toPromise";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";

import * as moment from "moment";

import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { StorageService } from "app/main/module/common/service/storage.service";
import { fuseAnimations } from "@fuse/animations";
import { AuthenticationService } from "../../common/service/authentication.service";
import { ProfileService } from "./profile.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
// import { AngularFireStorage } from '@angular/fire/storage';

@Component({
    selector: "profile",
    templateUrl: "./profile.component.html",
    styleUrls: ["./profile.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: [ProfileService],
})
export class ProfileComponent implements OnInit {
    userName: string;
    profileImage: any;
    email: any;
    userDataObj: Object;
    editProfileForm: FormGroup;
    url: any;
    name: any;
    showHideEditNameSection: boolean = false;
    updateProfileInfoForm: FormGroup;
    hasFirstDataShown: Boolean;
    isUploading = false;

    // userImage: any;
    /**
     * Constructor
     */
    constructor(
        private userService: StorageService,
        private authService: AuthenticationService,
        public profileService: ProfileService,
        public snackBar: MatSnackBar,
        private _formBuilder: FormBuilder,
        // private afStorage: AngularFireStorage,
        private dialog: MatDialog,
        private changeDetectorRef: ChangeDetectorRef // Inject ChangeDetectorRef
    ) {}
    ngOnInit(): void {
        this.hasFirstDataShown = false;
        this.userName = this.userService.getCookie("name");
        this.email = this.userService.getCookie("email");
        this.url = this.userService.getCookie("userImage");

        // console.log(this.userName);

        // this.getUserData();
        this.editProfileForm = this._formBuilder.group({
            userImage: [""],
        });

        this.updateProfileInfoForm = this._formBuilder.group({
            // updatedName: ['', [Validators.required, Validators.pattern(/^\S+/)], ''],
            updatedName: [""],
        });

        // console.log("obj:", this.userDataObj);
    }
    // getUserData() {
    //     this.authService.getUser(this.email).then(async (userDto) => {
    //         this.userDataObj = userDto;
    //         // // console.log(j);
    //         this.profileImage = userDto && userDto['userImage'] ? userDto['userImage'] : 'https://www.w3schools.com/howto/img_avatar.png';
    //         // // console.log(e);
    //         this.url = userDto && userDto['userImage'];
    //         // // console.log(l);
    //     });
    // }
    showEditNameSection(event) {
        this.showHideEditNameSection = !this.showHideEditNameSection;
        this.changeDetectorRef.detectChanges(); // Force change detection

        setTimeout(() => {
            const inputElement = document.getElementById("updateInput");
            if (inputElement) {
                inputElement.focus();
            }
        }, 0);
    }

    // updateProfileImage() {
    //     const dataObj = this.userDataObj;
    //     const fileData = this.editProfileForm.value.userImage;
    //     dataObj['userImage'] = fileData.name;
    //     const fileRef = this.afStorage.ref(`/user/${fileData.name}`);
    //     this.afStorage.upload(`/user/${fileData.name}`, fileData).then(imageData => {
    //         if (imageData) {
    //             // // console.log(a);
    //             fileRef.getDownloadURL().subscribe(url => {
    //                 if (url) {
    //                     dataObj['userImage'] = url;
    //                     this.profileService.updateUserInfoDetails(this.email, dataObj)
    //                         .then((data: any) => {
    //                             const msg = 'Profile image updated successfully';
    //                             this.openSnackBar(msg, 'OK');
    //                             // this.hasFirstDataShown = true;
    //                             // setTimeout(() => {
    //                             //      window.location.reload();
    //                             // }, 100)

    //                         }).catch((error) => {
    //                             this.handleError(error);
    //                         });
    //                         this.getUserData();
    //                 }
    //             })
    //         }
    //     })
    // }
    openSnackBar(message: string, action: string): void {
        this.snackBar.open(message, action, {
            duration: 3000,
        });
    }
    private handleError(error: Error): void {
        this.openSnackBar(error.message, "OK");
        // this.notify.update(error.message, 'error');
    }

    updateProfileInfo(event: Event, updatedNameRef: HTMLInputElement) {
        event.preventDefault();
        let userObj = this.userDataObj;
        let mystring = updatedNameRef.value;
        // console.log("Updated name:", mystring);
        mystring = mystring.toString().trim();
        if (mystring && mystring !== "" && mystring.length > 0) {
            userObj = {
                email: this.email,
                update_data: {
                    name: mystring,
                },
            };
            // console.log(userObj);
            this.hasFirstDataShown = true;
            this.isUploading = true;
            // console.log(this.isUploading);

            try {
                this.profileService.updateUserInfo(userObj).subscribe((res) => {
                    Object.entries(res).find(([key, value]) => {
                        if (key == "data" && value != "") {
                            const arr = value;
                            for (let a of arr) {
                                // console.log(arr);
                                this.name = a.name;
                                this.userService.setCookie("name", this.name);
                            }
                        }
                    });
                });
                this.hasFirstDataShown = false;
                this.userService.setCookie("name", updatedNameRef.value);
                const msg = "Profile updated successfully";
                this.openSnackBar(msg, "OK");
                this.isUploading = false;
                // console.log(this.isUploading);

                setTimeout(() => {
                    this.changeDetectorRef.detectChanges();
                    window.location.reload();
                }, 4000);
            } catch (error) {
                // this.hasFirstDataShown = false;
                this.openSnackBar(error, "OK");
            }
        } else {
            this.openSnackBar("Blank spaces are not allowed", "OK");
        }
    }

    onSelectFile(event: any) {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            const reader = new FileReader();

            reader.onload = (e: any) => {
                this.url = e.target.result;
            };

            reader.readAsDataURL(file);
            this.updateProfileImage(file);
        }
    }

    updateProfileImage(file: File) {
        const formData = new FormData();
        formData.append("file", file);

        this.hasFirstDataShown = true;
        this.isUploading = true;

        // console.log(this.isUploading);

        this.profileService.updateUserProfile(formData).subscribe(
            (res) => {
                Object.entries(res).find(([key, value]) => {
                    if (key == "data" && value != "") {
                        const arr = value;

                        for (let a of arr) {
                            // console.log(arr);
                            this.profileImage = a.profile_pic;
                            this.userService.setCookie(
                                "userImage",
                                this.profileImage
                            );
                        }
                    }
                });
                this.changeDetectorRef.detectChanges();
                this.isUploading = false;
                // console.log(this.isUploading);

                window.location.reload();

                // console.log("Upload response:", res);
                const msg = "Profile image updated successfully";
                this.openSnackBar(msg, "OK");
                this.hasFirstDataShown = false;
            },

            (error) => {
                console.error("Upload error:", error);
                this.isUploading = false;
                this.openSnackBar("Error updating profile image", "OK");
            }
        );
    }

    // openDialogWithRefEdit(ref: TemplateRef<any>): void {

    //     // // console.log(");
    //     this.updateProfileInfoForm.setValue({
    //         updatedName: 'testuser',

    //     });
    //     this.dialog.open(ref, { width: '400px' });

    // }
}
