import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { map } from "rxjs/operators";
import { Subject } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { StorageService } from "app/main/module/common/service/storage.service";
import { environment } from "environments/environment";

@Injectable({ providedIn: "root" })
export class TcenterService {
    private itemsSubject: BehaviorSubject<any[]>;
    public itemsObservable: Observable<any[]>;
    public pageSubject: BehaviorSubject<number>;
    public pageObservable: Observable<number>;
    latestEntry: any;
    startingEntry: any;
    ROWS = 10;
    currentPage = 0;
    protected table = "tcenter";
    protected orderBy = "";
    hasFirstDataShown = false;
    protected isOrderByNegative = true;
    baseUrl = environment.baseUrl;
    apiUrl = this.baseUrl + "/api/v1/";

    constructor(
        private userService: StorageService,
        public httpClient: HttpClient
    ) {}
    setTable(table, orderBy): void {
        this.table = table;
        this.orderBy = orderBy;
    }
    setPositiveOrderBy(): void {
        this.isOrderByNegative = false;
    }
    // getCollection(ref, queryFn?): Observable<any[]> {
    //     return this.afs.collection(ref, queryFn).snapshotChanges().pipe(map(actions => {
    //         return actions.map(a => {
    //             const data = a.payload.doc.data();
    //             const id = a.payload.doc.id;
    //             const doc = a.payload.doc;
    //             return { id, ...data as {}, doc };
    //         });
    //     }));
    // }
    // first(): any {
    //     this.currentPage = 0;
    //     this.itemsSubject = new BehaviorSubject([]);
    //     this.itemsObservable = this.itemsSubject.asObservable();
    //     this.pageSubject = new BehaviorSubject<number>(0);
    //     this.pageObservable = this.pageSubject.asObservable();
    //     let query = null;
    //     if (this.isOrderByNegative) {
    //         query = this.getCollection(this.getCollectionPath(), ref => ref
    //             .limit(this.ROWS));
    //     } else {
    //         query = this.getCollection(this.getCollectionPath(), ref => ref
    //             .orderBy(this.orderBy)
    //             .limit(this.ROWS));
    //     }

    //     query.subscribe(data => {
    //         this.pageSubject.next(0);
    //         this.latestEntry = data[data.length - 1].doc;
    //         this.startingEntry = data[0].doc;
    //         this.itemsSubject.next(data);
    //         // scoresRef.unsubscribe();
    //     });
    // }
    // next(): void {
    //     let query = null;
    //     if (this.isOrderByNegative) {
    //         query = this.getCollection(this.getCollectionPath(), ref => ref
    //             .startAfter(this.latestEntry)
    //             .limit(this.ROWS));
    //     } else {
    //         query = this.getCollection(this.getCollectionPath(), ref => ref
    //             .orderBy(this.orderBy)
    //             .startAfter(this.latestEntry)
    //             .limit(this.ROWS));
    //     }
    //     query.subscribe(data => {
    //         if (data.length) {

    //             this.currentPage++;
    //             this.pageSubject.next(this.currentPage);

    //             // And save it again for more queries
    //             this.latestEntry = data[data.length - 1].doc;
    //             this.startingEntry = data[0].doc;
    //             this.itemsSubject.next(data);
    //         }
    //         // scoresRef.unsubscribe();
    //     });
    // }

    // prev(): void {
    //     let query = null;
    //     if (this.isOrderByNegative) {
    //         query = this.getCollection(this.getCollectionPath(), ref => ref
    //             .orderBy(this.orderBy, 'desc')
    //             .startAfter(this.startingEntry)
    //             .limit(this.ROWS));
    //     } else {
    //         query = this.getCollection(this.getCollectionPath(), ref => ref
    //             .orderBy(this.orderBy)
    //             .startAfter(this.startingEntry)
    //             .limit(this.ROWS));
    //     }
    //     query.subscribe(data => {
    //         if (data.length) {
    //             data.reverse();
    //             // And save it again for more queries
    //             this.latestEntry = data[data.length - 1].doc;
    //             this.startingEntry = data[0].doc;
    //             this.currentPage--;
    //             this.pageSubject.next(this.currentPage);
    //             this.itemsSubject.next(data);
    //         }
    //         // scoresRef.unsubscribe();
    //     });
    // }

    getCollectionPath(): string {
        return "common/" + "resource" + "/" + this.table;
    }

    getTrcenter(category_id) {
        const token = this.userService.getCookie("token");
        const headers = new HttpHeaders().set("Authorization", `${token}`);
        const labId = this.userService.getCookie("lab_id");

        return this.httpClient.get(
            this.apiUrl + "tresource" + "?category_id=" + category_id,
            { headers }
        );
    }
}
